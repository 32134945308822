/* istanbul ignore file */
import { NotificationManager } from 'react-notifications';
import { all, delay, fork, put, select, takeEvery } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { filter, find, get, has, isEmpty, omit } from 'lodash';
import * as actions from '../constants';
import axiosRequest from '../../api';
import {
  ADHOC,
  ADDRESS,
  AMS_ADHOC_EXCLUDE_PROPS,
  AMS_EXCLUDE_PROPS,
  AMS_GLOBAL_ERROR_MESSAGE,
  AMS_SUBMIT_SUCCESS_TIMEOUT,
  ASTERISK,
  ATTACHMENT,
  CRM,
  EARTH_QUAKE_LOB,
  EOI_FILE,
  INSURANCE_BUSINESS_TYPES,
  INSURANCE_PRODUCTS,
  OTHER_PRODUCTS,
  PERSONAL_INFORMATION_KEY,
  AMS_FORM_FIELD,
  AMS_FILE_TYPE,
} from '../../../constants';
import {
  availableAMSLobs,
  checkValueWithOrganization,
  enableAMSFormField,
  getCarrierCompanyCode,
  getCarrierName,
  getCarrierOptionList,
  getCarrierPremium,
  getCarrierPremiumTerm,
  getLobHerdId,
  getOrgDetails,
  getOrganizationDetails,
  getSalesforceCustomerUUID,
  getSfdcPolicyHeadersDetails,
  getValidNumber,
  overrideAMSDetails,
  simplifyPhoneNumber,
  storeHerdId,
  removeExistingRate,
  removeExtraSpaces,
  getAms360DefaultValues,
  getAMSHeaderDefaults,
  getSalesforceLeadId,
  inIframe,
} from '../../../utils';
import {
  disableDivision,
  errorHandler,
  getCarrierUnderwriterList,
  setAMSDetails,
  setAMSDetailsLoader,
  setSfdcAMSDetailsLoader,
  setAMSDetailsSuccess,
  setIsSfdcSubmitSuccess,
  setAMSError,
  setHomeState,
  setSfdcAMSError,
  setAMSLOBDetails,
  setAutoQuoteBind,
  setCarrierUnderwriterList,
  setCarrierUnderwriterListForSfdc,
  setCarrierUnderwriterLoader,
  setFloodQuoteBind,
  setHomeQuoteBind,
  setLOBUpdated,
  setMaxAttemptReached,
  setPolicyBounded,
  setPolicyHeadersResponse,
  setQuakeQuoteBind,
  showAMSModal,
  showLOBAMSDetails,
  storeBoundedPolicyDetails,
  viewBoundedPolicyLoader,
  storeBoundedCarrierDetails,
  updateSelectedCarriers,
  setSfdcAmsFormData,
  setSfdcOrganizationDetails,
  storeCommonData,
} from '../actions';
import history from '../../../history';
import { isEnabledPRSv2Flow } from '../../../quote-application/utils';
import { updateQuoteListOnPolicyBound } from '../../../quote-application/components/product-rates/utils';
import { LineOfBusiness, PolicyRecordSource } from '../../../quote-application/enums';
const { DUE_DILIGENCE_FORM_DOCUMENT, SL2_FORM_DOCUMENT } = AMS_FORM_FIELD

const AMSDetailsActions: any = actions;
const ERROR_MESSAGE_PATH = 'response.data.Message';

const isOtherQuotes = (entity: any) => find(OTHER_PRODUCTS, ['value', entity]);

const getEntityName = (list: any, codeKey: any, entityData: any) => {
  const carrierData = find(list, carrier => carrier[codeKey] === entityData);
  return !isEmpty(carrierData)
    ? has(carrierData, 'Name')
      ? carrierData.Name.trim()
      : `${get(carrierData, 'FirstName', '')} ${get(carrierData, 'LastName', '')}`
    : '';
};

const getAmsLobName = (list: any, codeKey: any, entityData: any) => {
  const carrierData = find(list, carrier => carrier[codeKey] === entityData);
  return !isEmpty(carrierData) && has(carrierData, 'LineOfBusinessCode')
    ? carrierData.LineOfBusinessCode.trim()
    : '';
};

const getApplicantName = (personalDetails: any) =>
  `${get(personalDetails, 'first_name', '')} ${get(personalDetails, 'middle_name', '')} ${get(
    personalDetails,
    'last_name',
    ''
  )}`;

const getOrganizationCode = (store: any, entity: any) => {
  const { common, home } = store;
  let organizationCode = '';
  let lob =
    (!!entity && entity?.toLowerCase()) ??
    (!!common?.lineOfBusiness && common?.lineOfBusiness?.toLowerCase()) ??
    '';
  if (!isEmpty(lob) && lob === INSURANCE_PRODUCTS[1]) {
    organizationCode = !isEmpty(get(common.pconfig, 'pconfig.dale_config.code', ''))
      ? get(common.pconfig, 'pconfig.dale_config.code', '')
      : common?.pconfig?.dale_config?.code ?? '';
  } else {
    organizationCode = get(home.pconfig, 'dale_config.code', '') ?? home?.code ?? '';
  }
  return organizationCode;
};

const getAddressDetails = (address: any) =>
  `${get(address, 'street', '')} ${get(address, 'unit', '')} ${get(address, 'city', '')} ${get(
    address,
    'state',
    ''
  ).toUpperCase()} ${get(address, 'zip', '')}`;

const getSelectedCarrierData = (
  AMSDetails: any,
  policyBounded: any,
  details: any,
  currentSelectedLob: any
) => ({
  carrierId:
    !isEmpty(get(AMSDetails, 'selectedFloodQuote', null)) && !get(policyBounded, 'flood', false)
      ? AMSDetails?.selectedFloodQuote?.carrier_id
      : details.data[currentSelectedLob]?.carrierId,
  premium:
    !isEmpty(get(AMSDetails, 'selectedFloodQuote', null)) && !get(policyBounded, 'flood', false)
      ? getCarrierPremium(AMSDetails.selectedFloodQuote)
      : details?.data[currentSelectedLob]?.premium,
  term:
    !isEmpty(get(AMSDetails, 'selectedFloodQuote', null)) && !get(policyBounded, 'flood', false)
      ? getCarrierPremiumTerm(AMSDetails?.selectedFloodQuote)
      : details.data[currentSelectedLob]?.term,
  carrierUnderwriter:
    !isEmpty(get(AMSDetails, 'selectedFloodQuote', null)) && !get(policyBounded, 'flood', false)
      ? ''
      : details?.data[currentSelectedLob]?.carrierUnderwriter,
});

const getApiRoute = (type: string) => {
  if (type === ADHOC || type === CRM) {
    return '/adhoc/create-adhoc-policy-header';
  } else {
    return '/ams/create-policy-header';
  }
};

const getCurrentHerdId = (entity: any, reduxStore: any) => {
  const {
    common: { AMSLOBDetails },
  } = reduxStore;
  let herdId: any;
  let tempEntity = !!entity ? entity.toLowerCase() : '';
  if (!!AMSLOBDetails && !!tempEntity) {
    if (
      tempEntity === INSURANCE_BUSINESS_TYPES.HOME.toLowerCase() &&
      !!AMSLOBDetails?.home?.herdId
    ) {
      herdId = AMSLOBDetails.home.herdId;
    }
    if (
      tempEntity === INSURANCE_BUSINESS_TYPES.AUTO.toLowerCase() &&
      !!AMSLOBDetails?.auto?.herdId
    ) {
      herdId = AMSLOBDetails.auto.herdId;
    }
    if (
      tempEntity === INSURANCE_BUSINESS_TYPES.FLOOD.toLowerCase() &&
      !!AMSLOBDetails?.flood?.herdId
    ) {
      herdId = AMSLOBDetails.flood.herdId;
    }
  }
  return !!herdId ? herdId : getLobHerdId(tempEntity || '');
};

function* getUpdatedAMSDetails(
  currentData: any,
  organizationCode: any,
  lob: string,
  lookupDetails: any
): any {
  const store = yield select();
  const {
    common: { AMSDetails, lineOfBusiness, policyBounded, sfdcAmsFormData },
  } = store;
  const updatedDetails = overrideAMSDetails(currentData, organizationCode);
  if (
    !isEmpty(get(AMSDetails, 'selectedFloodQuote', null)) &&
    lineOfBusiness !== INSURANCE_PRODUCTS[1] &&
    !get(policyBounded, 'flood', false) &&
    isEmpty(updatedDetails.carrierUnderwriter)
  ) {
    yield put(
      getCarrierUnderwriterList({
        carrierId: AMSDetails?.selectedFloodQuote?.carrier_id,
      })
    );
  } else if (!isEmpty(updatedDetails.carrierUnderwriter)) {
    const carrierData = lookupDetails[lob];
    yield put(
      getCarrierUnderwriterList({
        carrierId: getValidNumber(get(carrierData, 'carrierId', '')),
      })
    );
  }
  updatedDetails.salesforceCustomerUUID =
    getSalesforceCustomerUUID(lookupDetails) ||
    getSalesforceCustomerUUID(AMSDetails) ||
    getSalesforceCustomerUUID(sfdcAmsFormData);
  updatedDetails.salesforceLeadId =
    getSalesforceLeadId(lookupDetails) ??
    getSalesforceLeadId(AMSDetails) ??
    getSalesforceLeadId(sfdcAmsFormData);
  return updatedDetails;
}

export const getEoiFilePutHeaders = (type: string) => ({
  headers: {
    'Content-Type': type,
    'Content-Disposition': ATTACHMENT,
    'Access-Control-Allow-Origin': ASTERISK,
  },
});

function* uploadAmsDocument(payload: any, orgCode: string, key: string): any {
  const store = yield select();
  const {
    common: { AMSDetails, sfdcAmsFormData },
    partner: { isProviderOneLogin },
  } = store;
  let formData: any = new FormData();
  formData.append("firstName", payload?.firstName || get(AMSDetails, 'firstName', '') || get(sfdcAmsFormData, 'personalInformation.firstName', ''));
  formData.append("lastName", payload?.lastName || get(AMSDetails, 'lastName', '') || get(sfdcAmsFormData, 'personalInformation.lastName', ''));
  formData.append("organizationCode", orgCode || get(sfdcAmsFormData, 'organizationCode', ''));
  formData.append('fileDetails', payload[key]);
  formData.append('fileType', key === SL2_FORM_DOCUMENT.KEY ? AMS_FILE_TYPE.SL2 : AMS_FILE_TYPE.DUE_DILIGENCE);
  const responseDetails = yield axiosRequest('POST', '/ams/upload-ams-document-file', isProviderOneLogin, formData);
  if (!!responseDetails?.data) {
    const { data } = responseDetails;
    return {
      fileName: !!data?.amsFileName ? data?.amsFileName : '',
      fileUrl: !!data?.amsFileUrl ? data?.amsFileUrl : '',
    }
  }
}

// tslint:disable-next-line: cyclomatic-complexity
function* AMSDetailsSubmit({ payload }: AppRedux.ActionPayload): any {
  yield put(setAMSDetailsLoader(true));
  yield put(setAMSError(''));
  try {
    const reduxDate = yield select();
    const {
      common,
      auth: { email, userId },
      partner: { isProviderOneLogin, producerDetails, storedRequestId },
      home: { pconfig },
    } = reduxDate;
    const { adhocOrganizationDetails, AMSDetails, policyBounded, lineOfBusiness, adhocBoundedLobs } = common;
    let currentEntity = !!payload?.businessLine
      ? payload.businessLine
      : !!lineOfBusiness
      ? lineOfBusiness
      : '';
    currentEntity =
      !!currentEntity && currentEntity?.toLowerCase() === INSURANCE_PRODUCTS[3]
        ? EARTH_QUAKE_LOB
        : currentEntity;
    const herdId =
      payload?.amsBindType === ADHOC ? payload?.herdId : getCurrentHerdId(currentEntity, reduxDate);
    let eoiFileName: any = null;
    let eoiFileUrl: any = null;
    let orgCode =
      adhocOrganizationDetails?.selectedAdhocOrganizationCode ||
      get(pconfig, 'dale_config.code', '') ||
      get(pconfig, 'pconfig.dale_config.code', '') ||
      get(common?.pconfig, 'pconfig.dale_config.code', '') ||
      get(common?.pconfig, 'dale_config.code', '') ||
      '';
    if (!!payload?.eoiFile && !eoiFileName && !eoiFileUrl) {
      const { eoiFile } = payload;
      let formData: any = new FormData();
      formData.append("firstName", payload?.amsBindType === ADHOC ? payload?.firstName : get(AMSDetails, 'firstName', ''));
      formData.append("lastName", payload?.amsBindType === ADHOC ? payload?.lastName : get(AMSDetails, 'lastName', ''));
      formData.append("organizationCode", orgCode);
      formData.append("eoiFile", eoiFile);
      const responseDetails = yield axiosRequest('POST', '/ams/upload-eoi-file', isProviderOneLogin, formData);
      if (!!responseDetails?.data) {
        const { data } = responseDetails;
        eoiFileName = !!data?.eoiFileName ? data?.eoiFileName : null;
        eoiFileUrl = !!data?.eoiFileUrl ? data?.eoiFileUrl : null;
      }
    }
    const quote: any = find(
      getCarrierOptionList(AMSDetails.carrierOptions, currentEntity),
      (quoteDetails: any) => getCarrierName(quoteDetails) === payload.carrierName
    );

    const AmsBindQuote: any = find(
      getCarrierOptionList(AMSDetails.carrierOptions),
      (item: any) => getCarrierCompanyCode(item) === payload.carrierName
    );

    const selectedEmployee = find(
      payload.employeeOptions,
      employee => employee.EmployeeCode === payload.employee
    );
    const existingCarrier = find(
      payload.quoteList,
      quoteDetails =>
        String(quoteDetails?.carrier_id) === String(quote?.carrierId) &&
        !get(quoteDetails, 'quoteError', false)
    );
    const bindByEmail = !isEmpty(email)
      ? email
      : producerDetails && !isEmpty(producerDetails.producerEmail)
      ? producerDetails.producerEmail
      : '';
    const bindById = !isEmpty(userId)
      ? userId
      : producerDetails && !isEmpty(producerDetails.producerCode)
      ? producerDetails.producerCode
      : '';
    const organizationDetails: any = getOrgDetails();
    let tempPayload: any = {
      ...omit(payload, AMS_EXCLUDE_PROPS, AMS_ADHOC_EXCLUDE_PROPS, EOI_FILE),
      lob:
        payload.amsBindType === ADHOC
          ? getAmsLobName(payload.lobOption, 'Description', String(currentEntity).trim())
          : !!currentEntity
          ? currentEntity.toUpperCase()
          : '',
      lobName: payload.amsBindType === ADHOC ? currentEntity : '',
      personalDetails: {
        address:
          payload.amsBindType === ADHOC
            ? get(payload, 'addressDetails', null)
            : get(AMSDetails, 'addressDetails', null),
        firstName:
          payload.amsBindType === ADHOC ? payload.firstName : get(AMSDetails, 'firstName', ''),
        middleName: get(AMSDetails, 'middleName', ''),
        lastName:
          payload.amsBindType === ADHOC ? payload.lastName : get(AMSDetails, 'lastName', ''),
        phoneNumber:
          simplifyPhoneNumber(get(payload, 'phoneNumber', '')) ||
          simplifyPhoneNumber(get(AMSDetails, 'phoneNumber', '')) ||
          '',
        email: payload.amsBindType === ADHOC ? payload.email : get(AMSDetails, 'email', ''),
        dob: payload.amsBindType === ADHOC ? payload.dob : get(AMSDetails, 'dob', ''),
      },
      employee: {
        code: get(selectedEmployee, 'EmployeeCode', ''),
        firstName: get(selectedEmployee, 'FirstName', ''),
        middleName: get(selectedEmployee, 'MiddleName', ''),
        lastName: get(selectedEmployee, 'LastName', ''),
      },
      policyNumber: String(payload.policyNumber).trim(),
      carrierUnderwriter: String(payload.carrierUnderwriter).trim(),
      carrierId:
        payload.amsBindType === ADHOC
          ? String(get(AmsBindQuote, 'CompanyCode', ''))
          : String(get(quote, 'carrierId', '')),
      herdId,
      bindBy: {
        email: bindByEmail,
        id: bindById,
      },
      isGatorQuote: !isEmpty(existingCarrier) ? true : false,
      carrierUnderwriterName: getEntityName(
        payload.carrierUnderwriterOptions,
        'CompanyCode',
        String(payload.carrierUnderwriter).trim()
      ),
      divisionName: getEntityName(
        payload.divisionOptions,
        'GLDivisionCode',
        String(payload.division).trim()
      ),
      executiveName: getEntityName(
        payload.executiveOptions,
        'EmployeeCode',
        String(payload.executive).trim()
      ),
      representativeName: getEntityName(
        payload.representativeOptions,
        'EmployeeCode',
        String(payload.representative).trim()
      ),
      groupName: getEntityName(payload.groupOptions, 'GLGroupCode', String(payload.group).trim()),
      departmentName: getEntityName(
        payload.departmentOptions,
        'GLDepartmentCode',
        String(payload.department).trim()
      ),
      brokerName: getEntityName(payload.brokerOptions, 'BrokerCode', String(payload.broker).trim()),
      assistedByName: getEntityName(
        payload.brokerOptions,
        'BrokerCode',
        String(payload.assistedBy).trim()
      ),
      employeeName: getEntityName(
        payload.employeeOptions,
        'EmployeeCode',
        String(payload.employee).trim()
      ),
      organizationCode: orgCode,
      organizationId:
        organizationDetails?.organizationId ||
        adhocOrganizationDetails?.selectedAdhocOrganizationId ||
        '',
    };

    let sl2FileDetails = {
      fileName: '',
      fileUrl: '',
    };
    let dueDiligenceFileDetails = {
      fileName: '',
      fileUrl: '',
    };
    if (!!payload?.sl2FormFile && isEmpty(sl2FileDetails?.fileName)) {
      sl2FileDetails = yield uploadAmsDocument(
        payload,
        orgCode,
        SL2_FORM_DOCUMENT.KEY
      );
    }
    if (!!payload?.dueDiigenceFormFile && isEmpty(dueDiligenceFileDetails?.fileName)) {
      dueDiligenceFileDetails = yield uploadAmsDocument(
        payload,
        orgCode,
        DUE_DILIGENCE_FORM_DOCUMENT.KEY
      );
    }
    if (!isEmpty(sl2FileDetails?.fileName)) {
      tempPayload.amsFileName = sl2FileDetails?.fileName;
      tempPayload.amsFileUrl = sl2FileDetails?.fileUrl;
      tempPayload.amsFileType = AMS_FILE_TYPE.SL2;
      delete tempPayload[SL2_FORM_DOCUMENT.KEY];
    }
    if (!isEmpty(dueDiligenceFileDetails?.fileName)) {
      tempPayload.amsFileName = dueDiligenceFileDetails?.fileName;
      tempPayload.amsFileUrl = dueDiligenceFileDetails?.fileUrl;
      tempPayload.amsFileType = AMS_FILE_TYPE.DUE_DILIGENCE;
      delete tempPayload[DUE_DILIGENCE_FORM_DOCUMENT.KEY];
    }
    if (!!enableAMSFormField(ADDRESS) && isEmpty(tempPayload?.address?.street) && !common?.isLOBAMSDetails) {
      tempPayload.personalDetails.address.street = payload?.address || '';
    }

    if (!isEmpty(eoiFileName)) {
      tempPayload.eoiFileName = eoiFileName;
      tempPayload.eoiFileUrl = eoiFileUrl;
    }
    if (
      !isEmpty(common?.selectedBusinessOption) &&
      isEmpty(getLobHerdId(common?.selectedBusinessOption || '')) &&
      !isEmpty(storedRequestId)
    ) {
      tempPayload.providerRequestId = storedRequestId;
    } else if (!!payload.isQuoteAdhocBind) {
      tempPayload.providerRequestId = storedRequestId;
      delete tempPayload.isQuoteAdhocBind;
    }
    tempPayload.policyRecordSource = !!inIframe()
      ? PolicyRecordSource.SFDC
      : PolicyRecordSource.GATOR;
    const details = yield axiosRequest(
      'POST',
      getApiRoute(payload.amsBindType),
      isProviderOneLogin,
      tempPayload
    );
    if (
      !!details?.data &&
      !isEmpty(details?.data) &&
      tempPayload?.lob &&
      isEmpty(details?.data?.customerData) &&
      !!payload.isQuoteAdhocBind
    ) {
      yield put(
        storeCommonData({
          adhocBoundedLobs: [...adhocBoundedLobs, tempPayload?.lob?.toLowerCase()],
        })
      );
    }
    if (
      !!details?.data &&
      payload.amsBindType !== ADHOC &&
      !!currentEntity &&
      isEmpty(get(details, 'data.customerData', ''))
    ) {
      if (!!details?.data?.carrierDetails) {
        const { carrierDetails } = details?.data;
        let lob =
          !!carrierDetails?.lob && carrierDetails?.lob?.toLowerCase() === EARTH_QUAKE_LOB
            ? INSURANCE_PRODUCTS[3]
            : carrierDetails?.lob;
        let premiumData = {
          [lob]: carrierDetails,
          isAmsBind: true,
        };
        yield put(storeBoundedCarrierDetails(premiumData));
      }
      const lobType = currentEntity?.toLowerCase();
      const isPRSv2Home = lobType?.toLowerCase() === LineOfBusiness.HOME && isEnabledPRSv2Flow();
      if (sessionStorage.selectedQuoteList) {
        let selectedQuoteList = sessionStorage.selectedQuoteList
          ? JSON.parse(sessionStorage.selectedQuoteList)
          : [];
        let selectedQuoteListIndex = selectedQuoteList.findIndex((el: any) => {
          return lobType.toUpperCase() === el.type;
        });
        const selectedCarrier = payload?.quoteList?.find((carrier: any) => {
          if (isPRSv2Home) {
            return String(carrier?.carrierId) === String(get(quote, 'carrierId', ''))
          }
          return String(carrier?.carrier_id) === String(get(quote, 'carrierId', ''))
        })
        if (isPRSv2Home) {
          updateQuoteListOnPolicyBound(
            tempPayload?.carrier_id?.toString() || tempPayload?.carrierId?.toString()
          );
        }
        if(selectedQuoteListIndex > -1) {
          if (selectedCarrier && !selectedCarrier?.quoteError) {
            selectedQuoteList[selectedQuoteListIndex].quote = selectedCarrier;
            sessionStorage.selectedQuoteList = JSON.stringify(selectedQuoteList);
          } else {
            const selectedQuotes = removeExistingRate(
              JSON.parse(sessionStorage.selectedQuoteList),
              lobType.toUpperCase()
            );
            sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
          }
        }
        let {
          common: { selectedCarriers },
        } = reduxDate;
        if (selectedCarriers && selectedCarriers[lobType] && !!selectedCarrier && !selectedCarrier?.quoteError) {
          selectedCarriers[lobType].carrierId = String(get(quote, 'carrierId', ''));
          if (
            payload?.premium &&
            (lobType === INSURANCE_PRODUCTS[2] || lobType === INSURANCE_PRODUCTS[3]) &&
            selectedCarrier?.premiumDetails?.length
          ) {
            const premiumDetails = selectedCarrier?.premiumDetails.find(
              (premiumDetail: any) => premiumDetail?.premium === Number(payload?.premium)
            );
            selectedCarriers[lobType].premium =
              !!premiumDetails && premiumDetails?.premium
                ? premiumDetails?.premium
                : selectedCarriers[lobType].premium;
          } else if (isPRSv2Home && selectedCarriers[lobType]?.data) {
            selectedCarriers[lobType].data.price = payload?.premium
              ? payload?.premium
              : selectedCarriers[lobType]?.data?.price;
          } else {
            selectedCarriers[lobType].premium = payload?.premium
              ? payload?.premium
              : selectedCarriers[lobType].premium;
          }
          yield put(updateSelectedCarriers(selectedCarriers));
        }
      }
    }
    if (
      !!details?.data?.herdId &&
      isEmpty(tempPayload?.herdId) &&
      payload?.amsBindType !== ADHOC &&
      !isEmpty(tempPayload?.lob)
    ) {
      storeHerdId(tempPayload?.lob, details?.data?.herdId);
    }
    if (!!details?.data && !isEmpty(get(details, 'data.customerData', ''))) {
      yield put(
        setAMSDetails({
          customerData: details?.data?.customerData,
          openPopup: true,
        })
      );
    } else if (!!details?.data) {
      yield put(
        setAMSDetails({
          customerData: [],
          openPopup: false,
        })
      );
      if (payload.amsBindType !== ADHOC && payload.amsBindType !== CRM) {
        yield put(setPolicyBounded({ ...policyBounded, [currentEntity]: true }));
      }
      yield put(showAMSModal(false));
      yield put(showLOBAMSDetails(false));
      yield put(setAMSDetailsSuccess(true));
      if (payload.amsBindType !== ADHOC) {
        if (!!currentEntity && currentEntity.toLowerCase() === INSURANCE_PRODUCTS[0]) {
          yield put(setHomeQuoteBind(get(quote, 'carrierId', '')));
        } else if (!!currentEntity && currentEntity.toLowerCase() === INSURANCE_PRODUCTS[2]) {
          yield put(setFloodQuoteBind(get(quote, 'carrierId', '')));
        } else if (!!currentEntity && currentEntity.toLowerCase() === EARTH_QUAKE_LOB) {
          yield put(setQuakeQuoteBind(get(quote, 'carrierId', '')));
        } else {
          yield put(setAutoQuoteBind(get(quote, 'carrierId', '')));
        }
      }
      yield delay(AMS_SUBMIT_SUCCESS_TIMEOUT);
      yield put(setAMSDetailsSuccess(false));
    }
  } catch (error) {
    yield put(
      setAMSDetails({
        customerData: [],
        openPopup: false,
      })
    );
    if (has(error, 'response.data.maxAttemptReached')) {
      yield put(setMaxAttemptReached(true));
    }
    yield put(setAMSError(get(error, ERROR_MESSAGE_PATH, AMS_GLOBAL_ERROR_MESSAGE)));
    yield put(errorHandler(error));
  }
  yield put(setAMSError(''));
  yield put(setAMSDetailsLoader(false));
}

export function* AMSDetailsSubmitWatcher() {
  yield takeEvery(AMSDetailsActions.AMS_DETAILS_SUBMIT, AMSDetailsSubmit);
}

function* fetchAMSDetails({ payload }: AppRedux.ActionPayload): any {
  yield put(setCarrierUnderwriterLoader(false));
  yield put(setAMSDetailsLoader(true));
  try {
    let currentDetails: any;
    const store = yield select();
    const herdId = getLobHerdId(String(payload.entity)?.toLowerCase() || '');
    const {
      common: { AMSDetails, lineOfBusiness, policyBounded },
    } = store;
    let organizationCode = getOrganizationCode(
      store,
      String(payload.entity).toLowerCase() ?? lineOfBusiness
    );
    if (payload.entity === INSURANCE_PRODUCTS[0] || isOtherQuotes(payload.entity)) {
      const {
        home: { address, personal_details, quoteList },
        applicant,
      } = store;
      const onlyQuoteList = filter(quoteList, quote => !quote.quoteError);
      currentDetails = {
        address: !!address?.city ? address : applicant?.address,
        personal_details,
        quoteList: [...onlyQuoteList],
      };
    } else {
      const {
        applicant: { address, personal_details },
        quote: { quoteList },
      } = store;
      currentDetails = {
        address,
        personal_details,
        quoteList,
      };
    }
    if (!currentDetails?.personal_details) {
      const {
        applicant: { personal_details },
      } = store;
      currentDetails.personal_details = personal_details;
    }
    yield put(
      setAMSDetails({
        email: get(currentDetails, 'personal_details.email', ''),
        dob: get(currentDetails, 'personal_details.date_of_birth', ''),
        fullName: removeExtraSpaces(getApplicantName(currentDetails.personal_details)),
        firstName: get(currentDetails, 'personal_details.first_name', ''),
        middleName: get(currentDetails, 'personal_details.middle_name', ''),
        lastName: get(currentDetails, 'personal_details.last_name', ''),
        addressDetails: {
          ...currentDetails.address,
          state: get(currentDetails, 'address.state', '').toUpperCase(),
        },
        phoneNumber: get(currentDetails.personal_details, 'phone_number', ''),
        address: removeExtraSpaces(getAddressDetails(currentDetails.address)),
        lob: payload.entity,
      })
    );
    const {
      partner: { isProviderOneLogin, storedRequestId },
    } = yield select();
    const organizationDetails: any = getOrgDetails();
    let requestPayload: any = {
      herdId,
      lob: String(payload.entity).toLowerCase(),
      organizationId: organizationDetails?.organizationId || '',
    };
    if (isEmpty(herdId)) {
      requestPayload.providerRequestId = storedRequestId;
    }
    const details = yield axiosRequest('POST', '/ams/get-policy-header', isProviderOneLogin, {
      ...requestPayload
    });
    if (details.data) {
      if (isEmpty(get(details, 'data.division', ''))) {
        const { orgPresent } = checkValueWithOrganization(
          get(details, 'data.divisionList', ''),
          organizationCode,
          'GLDivisionCode'
        );
        if (orgPresent) {
          yield put(disableDivision(true));
        }
      }
      const currentSelectedLob = !!payload?.entity
        ? payload.entity
        : !isEmpty(get(AMSDetails, 'selectedFloodQuote', null)) &&
          !get(policyBounded, 'flood', false)
        ? INSURANCE_PRODUCTS[2]
        : availableAMSLobs(policyBounded, lineOfBusiness)[0];

      yield put(setAMSLOBDetails(details.data));
      if (
        !isEmpty(get(AMSDetails, 'selectedFloodQuote', null)) &&
        lineOfBusiness !== INSURANCE_PRODUCTS[1] &&
        !get(policyBounded, 'flood', false)
      ) {
        yield put(
          setAMSLOBDetails({
            ...details.data,
            flood: {
              ...details.data.flood,
              ...getSelectedCarrierData(AMSDetails, policyBounded, details, currentSelectedLob),
            },
          })
        );
      }

      const currentData = {
        ...details.data[currentSelectedLob],
        carrierList: get(details, 'data.carrierList', {}),
        departmentList: get(details, 'data.departmentList', []),
        divisionList: get(details, 'data.divisionList', []),
        employeeList: get(details, 'data.employeeList', []),
        brokerList: get(details, 'data.brokerList', []),
        executivesList: get(details, 'data.executivesList', []),
        groupList: get(details, 'data.groupList', []),
        representativesList: get(details, 'data.representativesList', []),
        ...getAms360DefaultValues({
          ...details?.data,
          amsHeaderDefaults: getAMSHeaderDefaults(organizationDetails?.organizationCode),
        }),
        ...getSelectedCarrierData(AMSDetails, policyBounded, details, currentSelectedLob),
      };
      const updatedDetails = yield getUpdatedAMSDetails(
        currentData,
        organizationCode,
        currentSelectedLob,
        details?.data
      );
      yield put(setAMSDetails(updatedDetails));
      if (!!details?.status && details?.status === 200) {
        yield put(setPolicyHeadersResponse(true));
      }
    }
  } catch (error) {
    if (has(error, 'response.data.isPolicyBound')) {
      yield put(setMaxAttemptReached(true));
    }
    yield put(setAMSError(get(error, ERROR_MESSAGE_PATH, AMS_GLOBAL_ERROR_MESSAGE)));
    yield put(errorHandler(error));
  }
  yield put(setAMSError(''));
  yield put(setAMSDetailsLoader(false));
}

export function* fetchAMSDetailsWatcher() {
  yield takeEvery(AMSDetailsActions.FETCH_AMS_DETAILS, fetchAMSDetails);
}

function* fetchLOBAMSDetails(): any {
  yield put(setCarrierUnderwriterLoader(false));
  yield put(setAMSDetailsLoader(true));
  try {
    const {
      partner: { isProviderOneLogin, storedRequestId },
      common: { adhocOrganizationDetails }
    } = yield select();
    const details = yield axiosRequest(
      'POST',
      '/adhoc/get-adhoc-policy-header',
      isProviderOneLogin,
      {
        organizationCode: !isEmpty(adhocOrganizationDetails?.selectedAdhocOrganizationCode)
          ? adhocOrganizationDetails?.selectedAdhocOrganizationCode
          : '',
        organizationId: adhocOrganizationDetails?.selectedAdhocOrganizationId || '',
        providerRequestId: storedRequestId || '',
      }
    );
    if (details.data) {
      yield put(setAMSLOBDetails({ ...details.data, carrierList: details.data.companyList }));
      const currentData = {
        carrierList: get(details, 'data.companyList', {}),
        departmentList: get(details, 'data.departmentList', []),
        divisionList: get(details, 'data.divisionList', []),
        employeeList: get(details, 'data.employeeList', []),
        brokerList: get(details, 'data.brokerList', []),
        executivesList: get(details, 'data.executivesList', []),
        groupList: get(details, 'data.groupList', []),
        representativesList: get(details, 'data.representativesList', []),
        lobList: get(details, 'data.lobList', []),
        ...getAms360DefaultValues({
          ...details?.data,
          amsHeaderDefaults: getAMSHeaderDefaults(adhocOrganizationDetails?.selectedAdhocOrganizationCode),
        }),
      };
      const updatedDetails = yield getUpdatedAMSDetails(currentData, '', '', details?.data);
      yield put(setAMSDetails(updatedDetails));

      yield put(setAMSDetailsLoader(false));
    }
  } catch (error) {
    yield put(setAMSError(get(error, ERROR_MESSAGE_PATH, AMS_GLOBAL_ERROR_MESSAGE)));
    yield put(errorHandler(error));
  }
  yield put(setAMSError(''));
  yield put(setAMSDetailsLoader(false));
}
export function* fetchLOBAMSDetailsWatcher() {
  yield takeEvery(AMSDetailsActions.FETCH_LOB_AMS_DETAILS, fetchLOBAMSDetails);
}

function* fetchLOBSFDCDetails(): any {
  try {
    const {
      partner: { isProviderOneLogin },
      common: { sfdcOrganizationDetails }
    } = yield select();

    const organizationCode = !isEmpty(sfdcOrganizationDetails?.selectedSfdcOrganizationCode)
      ? sfdcOrganizationDetails?.selectedSfdcOrganizationCode
      : '';

    if (isEmpty(organizationCode)) {
      return;
    }
    const organizationDetails: any = getOrgDetails();
    const details = yield axiosRequest(
      'POST',
      '/adhoc/get-adhoc-policy-header',
      isProviderOneLogin,
      {
        organizationCode,
        organizationId: organizationDetails?.organizationId || '',
      }
    );
    if (details.data) {
      yield put(setAMSLOBDetails({ ...details.data, carrierList: details.data.companyList }));
      const currentData = {
        carrierList: get(details, 'data.companyList', {}),
        departmentList: get(details, 'data.departmentList', []),
        divisionList: get(details, 'data.divisionList', []),
        employeeList: get(details, 'data.employeeList', []),
        brokerList: get(details, 'data.brokerList', []),
        executivesList: get(details, 'data.executivesList', []),
        groupList: get(details, 'data.groupList', []),
        representativesList: get(details, 'data.representativesList', []),
        lobList: get(details, 'data.lobList', []),
        ...getAms360DefaultValues({
          ...details?.data,
          amsHeaderDefaults: getAMSHeaderDefaults(organizationDetails?.organizationCode),
        }),
      };
      const updatedDetails = yield getUpdatedAMSDetails(currentData, '', '', details?.data);

      yield put(setSfdcAmsFormData(updatedDetails));
    }

  } catch (error) {
    yield put(setAMSError(get(error, ERROR_MESSAGE_PATH, AMS_GLOBAL_ERROR_MESSAGE)));
    yield put(errorHandler(error));
  }
  yield put(setAMSError(''));
  yield put(setAMSDetailsLoader(false));
}
export function* fetchLOBSFDCDetailsWatcher() {
  yield takeEvery(AMSDetailsActions.FETCH_SFDC_LOB_DETAILS, fetchLOBSFDCDetails);
}

function* getCarrierUnderwriterListDetails({ payload }: AppRedux.ActionPayload): any {
  const store = yield select();
  const {
    common,
    partner: { isProviderOneLogin },
    home: { pconfig }
  } = store;
  const { adhocOrganizationDetails, lineOfBusiness } = common;
  yield put(setCarrierUnderwriterLoader(true));
  let organizationCode = getOrganizationCode(store, lineOfBusiness);
  if (!isEmpty(organizationCode)) {
    payload.organizationCode = organizationCode;
  }
  let orgCode =
    adhocOrganizationDetails?.selectedAdhocOrganizationCode ||
    get(pconfig, 'dale_config.code', '') ||
    get(pconfig, 'pconfig.dale_config.code', '') ||
    get(common?.pconfig, 'pconfig.dale_config.code', '') ||
    get(common?.pconfig, 'dale_config.code', '') || '';
  try {
    const details = yield axiosRequest(
      'POST',
      '/ams/get-carrier-underwriters',
      isProviderOneLogin,
      {
        ...payload,
        organizationCode: orgCode,
      }
    );
    if (details.data) {
      yield put(setCarrierUnderwriterList(get(details, 'data.companyList', [])));
      const {
        common: { AMSDetails },
      } = yield select();
      if (!isEmpty(AMSDetails.carrierUnderwriter)) {
        const selectedCarrierUnderwriter = filter(
          AMSDetails.carrierUnderwriterOptions,
          (carrier) => carrier.CompanyCode === AMSDetails.carrierUnderwriter
        );
        if (isEmpty(selectedCarrierUnderwriter)) {
          yield put(
            setAMSDetails({
              carrierUnderwriter: '',
            })
          );
        }
      }
    }
  } catch (error) {
    yield put(
      setAMSDetails({
        carrierUnderwriter: '',
        carrierUnderwriterOptions: [],
      })
    );
    yield put(setAMSError(get(error, ERROR_MESSAGE_PATH, AMS_GLOBAL_ERROR_MESSAGE)));
    yield put(errorHandler(error));
  }
  yield put(setAMSError(''));
  yield put(setCarrierUnderwriterLoader(false));
}

export function* getCarrierUnderwriterWatcher() {
  yield takeEvery(AMSDetailsActions.GET_CARRIER_UNDERWRITER_LIST, getCarrierUnderwriterListDetails);
}

function* getCarrierUnderwriterListDetailsForSfdc({ payload }: AppRedux.ActionPayload): any {
  const store = yield select();
  const {
    common,
    partner: { isProviderOneLogin },
    home: { pconfig }
  } = store;
  const { sfdcOrganizationDetails, lineOfBusiness } = common;
  yield put(setCarrierUnderwriterLoader(true));
  let organizationCode = getOrganizationCode(store, lineOfBusiness);

  if (!isEmpty(organizationCode)) {
    payload.organizationCode = organizationCode;
  }

  let orgCode =
  sfdcOrganizationDetails?.selectedSfdcOrganizationCode ||
    get(pconfig, 'dale_config.code', '') ||
    get(pconfig, 'pconfig.dale_config.code', '') ||
    get(common?.pconfig, 'pconfig.dale_config.code', '') ||
    get(common?.pconfig, 'dale_config.code', '') || '';
  try {
    const details = yield axiosRequest(
      'POST',
      '/ams/get-carrier-underwriters',
      isProviderOneLogin,
      {
        ...payload,
        organizationCode: orgCode,
      }
    );
    if (details.data) {
      yield put(setCarrierUnderwriterListForSfdc(get(details, 'data.companyList', [])));
      const {
        common: { sfdcAmsFormData },
      } = yield select();
      if (!isEmpty(sfdcAmsFormData.carrierUnderwriter)) {
        const selectedCarrierUnderwriter = filter(
          sfdcAmsFormData.carrierUnderwriterOptions,
          (carrier) => carrier.CompanyCode === sfdcAmsFormData.carrierUnderwriter
        );
        if (isEmpty(selectedCarrierUnderwriter)) {
          yield put(
            setSfdcAmsFormData({
              carrierUnderwriter: '',
            })
          );
        }
      }
    }
  } catch (error) {
    yield put(
      setSfdcAmsFormData({
        carrierUnderwriter: '',
        carrierUnderwriterOptions: [],
      })
    );
    yield put(setAMSError(get(error, ERROR_MESSAGE_PATH, AMS_GLOBAL_ERROR_MESSAGE)));
    yield put(errorHandler(error));
  }
  yield put(setAMSError(''));
  yield put(setCarrierUnderwriterLoader(false));
}

export function* getCarrierUnderwriterForSfdcWatcher() {
  yield takeEvery(AMSDetailsActions.GET_CARRIER_UNDERWRITER_LIST_FOR_SFDC, getCarrierUnderwriterListDetailsForSfdc);
}

function* setAMSLOBChangedDetails({ payload }: AppRedux.ActionPayload): any {
  const store = yield select();
  const {
    common: { AMSLOBDetails, AMSDetails },
  } = store;
  const organizationCode = getOrganizationCode(store, payload);
  const updatedData = {
    ...AMSLOBDetails[payload],
    carrierList: get(AMSDetails, 'carrierOptions', {}),
    departmentList: get(AMSDetails, 'departmentOptions', []),
    divisionList: get(AMSDetails, 'divisionOptions', []),
    employeeList: get(AMSDetails, 'employeeOptions', []),
    brokerList: get(AMSDetails, 'brokerOptions', []),
    executivesList: get(AMSDetails, 'executiveOptions', []),
    groupList: get(AMSDetails, 'groupOptions', []),
    representativesList: get(AMSDetails, 'representativeOptions', []),
  };
  const updatedDetails = yield getUpdatedAMSDetails(
    updatedData,
    organizationCode,
    payload,
    AMSLOBDetails
  );
  yield put(setLOBUpdated(true));
  yield put(
    setAMSDetails({
      ...updatedDetails,
      lob: payload,
    })
  );
  yield delay(10);
  yield put(setLOBUpdated(false));
}

export function* setAMSLOBChangedWatcher() {
  yield takeEvery(AMSDetailsActions.SET_AMS_LOB_CHANGED, setAMSLOBChangedDetails);
}

function* fetchBoundedPolicyDetails({ payload }: AppRedux.ActionPayload): any {
  yield put(viewBoundedPolicyLoader(true));
  try {
    const {
      partner: { isProviderOneLogin },
    } = yield select();
    const responseDetails = yield axiosRequest(
      'POST',
      '/ams/get-bound-policy-info',
      isProviderOneLogin,
      {
        ...payload,
      }
    );
    if (!!responseDetails?.data && !!responseDetails?.status && responseDetails?.status === 200) {
      const { policyData } = responseDetails?.data;
      yield put(storeBoundedPolicyDetails(policyData) as any);
    } else {
      if (!!responseDetails?.data?.Message) {
        yield NotificationManager.error(responseDetails?.data?.Message);
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (!!axiosError?.response && axiosError?.response?.status === 404) {
      yield NotificationManager.error(axiosError?.response?.data?.Message);
    } else {
      yield put(errorHandler(axiosError));
    }
  }
  yield put(viewBoundedPolicyLoader(false));
}

export function* fetchBoundedPolicyDetailsWatcher() {
  yield takeEvery(AMSDetailsActions.FETCH_BOUNDED_POLICY_DATA, fetchBoundedPolicyDetails);
}
// Add Non-Gator supported LOB to AMS360
function* fetchDataForSfdcAmsForm({ payload }: any): any {
  try {
    yield put(
      setSfdcOrganizationDetails({
        selectedAdhocOrganizationCode: '',
        eoiUploadRequired: '',
      })
    );
    yield put(setSfdcAmsFormData({ isPolicyBounded: false }));
    const {
      partner: { isProviderOneLogin },
    } = yield select();

    const responseDetails = yield axiosRequest(
      'GET',
      `/api/v1/crm/ams/${payload?.herdId}`,
      isProviderOneLogin,
    );

    if (!!responseDetails?.data) {
      yield put(
        setSfdcAmsFormData({
          ...responseDetails?.data,
          herdId: payload?.herdId,
        })
      );
      const { organizationCode, eoiUploadRequired } = responseDetails?.data;
      getOrganizationDetails(organizationCode);
      yield put(
        setSfdcOrganizationDetails({
          selectedSfdcOrganizationCode: organizationCode,
          eoiUploadRequired: eoiUploadRequired || '',
        })
      );
      getSfdcPolicyHeadersDetails();
    } else {
      if (!!responseDetails?.data?.Message) {
        yield NotificationManager.error(responseDetails?.data?.Message);
      }
    }
  } catch (error) {
    const axiosError = error as AxiosError<any>;
    if (
      !!axiosError?.response &&
      (axiosError?.response?.status === 404 || axiosError?.response?.status === 500)
    ) {
      yield put(
        setHomeState({
          showError: true,
          notFoundError: true,
        } as any)
      );
      history.push('/error');
      if (!isEmpty(axiosError?.response?.data?.message)) {
        yield NotificationManager.error(axiosError?.response?.data?.message);
      }
    } else {
      yield put(errorHandler(axiosError));
    }
  }
}

export function* fetchDataForSfdcAmsFormWatcher() {
  yield takeEvery(AMSDetailsActions.FETCH_SFDC_AMS_FORM_DATA, fetchDataForSfdcAmsForm);
}

// tslint:disable-next-line: cyclomatic-complexity
function* sfdcAmsDetailsSubmit({ payload }: AppRedux.ActionPayload): any {
  yield put(setSfdcAMSDetailsLoader(true));
  yield put(setSfdcAMSError(''));
  try {
    const reduxDate = yield select();
    const {
      common,
      auth: { email, userId },
      partner: { isProviderOneLogin, producerDetails },
      home: { pconfig },
    } = reduxDate;

    const { sfdcOrganizationDetails, sfdcAmsFormData, policyBounded } = common;
    let currentEntity = !!payload?.businessLine
      ? payload.businessLine
      : '';
    const herdId = payload ? payload?.herdId : getCurrentHerdId(currentEntity, reduxDate);
    let eoiFileName: any = null;
    let eoiFileUrl: any = null;
    let orgCode =
      sfdcOrganizationDetails?.selectedSfdcOrganizationCode ||
      get(pconfig, 'dale_config.code', '') ||
      get(pconfig, 'pconfig.dale_config.code', '') ||
      get(common?.pconfig, 'pconfig.dale_config.code', '') ||
      get(common?.pconfig, 'dale_config.code', '') ||
      '';
    if (!!payload?.eoiFile && !eoiFileName && !eoiFileUrl) {
      const { eoiFile } = payload;
      let formData: any = new FormData();
      formData.append('firstName', payload.firstName || get(sfdcAmsFormData, 'personalInformation.firstName', ''));
      formData.append('lastName', payload.lastName || get(sfdcAmsFormData, 'personalInformation.lastName', ''));
      formData.append('organizationCode', get(sfdcAmsFormData, 'organizationCode', ''));
      formData.append('eoiFile', eoiFile);
      const responseDetails = yield axiosRequest(
        'POST',
        '/ams/upload-eoi-file',
        isProviderOneLogin,
        formData
      );
      if (!!responseDetails?.data) {
        const { data } = responseDetails;
        eoiFileName = !!data?.eoiFileName ? data?.eoiFileName : null;
        eoiFileUrl = !!data?.eoiFileUrl ? data?.eoiFileUrl : null;
      }
    }

    const quote: any = find(
      getCarrierOptionList(sfdcAmsFormData.carrierOptions, currentEntity),
      (quoteDetails: any) => getCarrierName(quoteDetails) === payload.carrierName
    );

    const AmsBindQuote: any = find(
      getCarrierOptionList(sfdcAmsFormData.carrierOptions),
      (item: any) => getCarrierCompanyCode(item) === payload.carrierName
    );

    const selectedEmployee = find(
      payload.employeeOptions,
      employee => employee.EmployeeCode === payload.employee
    );

    const existingCarrier = find(
      payload.quoteList,
      quoteDetails =>
        String(quoteDetails?.carrier_id) === String(quote?.carrierId) &&
        !get(quoteDetails, 'quoteError', false)
    );

    const bindByEmail = !isEmpty(email)
      ? email
      : producerDetails && !isEmpty(producerDetails.producerEmail)
      ? producerDetails.producerEmail
      : '';

    const bindById = !isEmpty(userId)
      ? userId
      : producerDetails && !isEmpty(producerDetails.producerCode)
      ? producerDetails.producerCode
      : '';
    const organizationDetails: any = getOrgDetails();
    let tempPayload: any = {
      ...omit(
        payload,
        AMS_EXCLUDE_PROPS,
        AMS_ADHOC_EXCLUDE_PROPS,
        EOI_FILE,
        PERSONAL_INFORMATION_KEY
      ),
      lob:
        payload.amsBindType === CRM
          ? getAmsLobName(payload.lobOption, 'Description', String(currentEntity).trim())
          : !!currentEntity
          ? currentEntity.toUpperCase()
          : '',
      lobName: payload ? currentEntity : '',
      personalDetails: {
        address:
          payload.amsBindType === CRM
            ? get(payload, 'addressDetails', null)
            : get(sfdcAmsFormData, 'addressDetails', null),
        firstName:
          payload?.firstName || get(sfdcAmsFormData, 'personalInformation.firstName', '') || '',
        middleName: payload?.middleName || get(sfdcAmsFormData, 'middleName', ''),
        lastName:
          payload?.lastName || get(sfdcAmsFormData, 'personalInformation.lastName', '') || '',
        phoneNumber:
          simplifyPhoneNumber(payload.phoneNumber) ||
          simplifyPhoneNumber(get(sfdcAmsFormData, 'personalInformation.phone', '')) ||
          '',
        email: payload?.email || get(sfdcAmsFormData, 'personalInformation.email', '') || '',
        dob: payload?.dob || get(sfdcAmsFormData, 'personalInformation.dateOfBirth', '') || '',
      },
      employee: {
        code: get(selectedEmployee, 'EmployeeCode', ''),
        firstName: get(selectedEmployee, 'FirstName', ''),
        middleName: get(selectedEmployee, 'MiddleName', ''),
        lastName: get(selectedEmployee, 'LastName', ''),
      },
      policyNumber: String(payload.policyNumber).trim(),
      carrierUnderwriter: String(payload.carrierUnderwriter).trim(),
      carrierId:
        payload.amsBindType === CRM
          ? String(get(AmsBindQuote, 'CompanyCode', ''))
          : String(get(quote, 'carrierId', '')),
      herdId,
      bindBy: {
        email: bindByEmail,
        id: bindById,
      },
      isGatorQuote: !isEmpty(existingCarrier) ? true : false,
      carrierUnderwriterName: getEntityName(
        payload.carrierUnderwriterOptions,
        'CompanyCode',
        String(payload.carrierUnderwriter).trim()
      ),
      divisionName: getEntityName(
        payload.divisionOptions,
        'GLDivisionCode',
        String(payload.division).trim()
      ),
      executiveName: getEntityName(
        payload.executiveOptions,
        'EmployeeCode',
        String(payload.executive).trim()
      ),
      representativeName: getEntityName(
        payload.representativeOptions,
        'EmployeeCode',
        String(payload.representative).trim()
      ),
      groupName: getEntityName(payload.groupOptions, 'GLGroupCode', String(payload.group).trim()),
      departmentName: getEntityName(
        payload.departmentOptions,
        'GLDepartmentCode',
        String(payload.department).trim()
      ),
      brokerName: getEntityName(payload.brokerOptions, 'BrokerCode', String(payload.broker).trim()),
      employeeName: getEntityName(
        payload.employeeOptions,
        'EmployeeCode',
        String(payload.employee).trim()
      ),
      assistedByName: getEntityName(
        payload.brokerOptions,
        'BrokerCode',
        String(payload.assistedBy).trim()
      ),
      organizationCode: orgCode,
      organizationId: organizationDetails?.organizationId || '',
    };

    let sl2FileDetails = {
      fileName: '',
      fileUrl: '',
    };
    let dueDiligenceFileDetails = {
      fileName: '',
      fileUrl: '',
    };
    if (!!payload?.sl2FormFile && isEmpty(sl2FileDetails?.fileName)) {
      sl2FileDetails = yield uploadAmsDocument(
        payload,
        orgCode,
        SL2_FORM_DOCUMENT.KEY
      );
    }
    if (!!payload?.dueDiigenceFormFile && isEmpty(dueDiligenceFileDetails?.fileName)) {
      dueDiligenceFileDetails = yield uploadAmsDocument(
        payload,
        orgCode,
        DUE_DILIGENCE_FORM_DOCUMENT.KEY
      );
    }
    if (!isEmpty(sl2FileDetails?.fileName)) {
      tempPayload.amsFileName = sl2FileDetails?.fileName;
      tempPayload.amsFileUrl = sl2FileDetails?.fileUrl;
      tempPayload.amsFileType = AMS_FILE_TYPE.SL2;
      delete tempPayload[SL2_FORM_DOCUMENT.KEY];
    }
    if (!isEmpty(dueDiligenceFileDetails?.fileName)) {
      tempPayload.amsFileName = dueDiligenceFileDetails?.fileName;
      tempPayload.amsFileUrl = dueDiligenceFileDetails?.fileUrl;
      tempPayload.amsFileType = AMS_FILE_TYPE.DUE_DILIGENCE;
      delete tempPayload[DUE_DILIGENCE_FORM_DOCUMENT.KEY];
    }
    if (!isEmpty(eoiFileName)) {
      tempPayload.eoiFileName = eoiFileName;
      tempPayload.eoiFileUrl = eoiFileUrl;
    }
    if(payload?.isCarrierRiskConsented) {
      tempPayload.isCarrierRiskConsented = true;
    }
    tempPayload.policyRecordSource = !!inIframe()
      ? PolicyRecordSource.SFDC
      : PolicyRecordSource.GATOR;
    const details = yield axiosRequest(
      'POST',
      getApiRoute(payload.amsBindType),
      isProviderOneLogin,
      tempPayload
    );
    if (!!details && !!currentEntity && isEmpty(get(details, 'data.customerData', ''))) {
      if (!!details?.carrierDetails) {
        const { carrierDetails } = details;
        let lob =
          !!carrierDetails?.lob && carrierDetails?.lob?.toLowerCase() === EARTH_QUAKE_LOB
            ? INSURANCE_PRODUCTS[3]
            : carrierDetails?.lob;
        let premiumData = {
          [lob]: carrierDetails,
          isAmsBind: true,
        };
        yield put(storeBoundedCarrierDetails(premiumData));
      }
      const lobType = currentEntity?.toLowerCase();
      if (sessionStorage.selectedQuoteList) {
        let selectedQuoteList = sessionStorage.selectedQuoteList
          ? JSON.parse(sessionStorage.selectedQuoteList)
          : [];
        let selectedQuoteListIndex = selectedQuoteList.findIndex((el: any) => {
          return lobType.toUpperCase() === el.type;
        });
        const selectedCarrier = payload?.quoteList?.find(
          (carrier: any) => String(carrier?.carrier_id) === String(get(quote, 'carrierId', ''))
        );
        if (selectedQuoteListIndex > -1) {
          if (selectedCarrier && !selectedCarrier?.quoteError) {
            selectedQuoteList[selectedQuoteListIndex].quote = selectedCarrier;
            sessionStorage.selectedQuoteList = JSON.stringify(selectedQuoteList);
          } else {
            const selectedQuotes = removeExistingRate(
              JSON.parse(sessionStorage.selectedQuoteList),
              lobType.toUpperCase()
            );
            sessionStorage.selectedQuoteList = JSON.stringify(selectedQuotes);
          }
        }
        let {
          common: { selectedCarriers },
        } = reduxDate;
        if (
          selectedCarriers &&
          selectedCarriers[lobType] &&
          !!selectedCarrier &&
          !selectedCarrier?.quoteError
        ) {
          selectedCarriers[lobType].carrierId = String(get(quote, 'carrierId', ''));
          if (
            payload?.premium &&
            (lobType === INSURANCE_PRODUCTS[2] || lobType === INSURANCE_PRODUCTS[3]) &&
            selectedCarrier?.premiumDetails?.length
          ) {
            const premiumDetails = selectedCarrier?.premiumDetails.find(
              (premiumDetail: any) => premiumDetail?.premium === Number(payload?.premium)
            );
            selectedCarriers[lobType].premium =
              !!premiumDetails && premiumDetails?.premium
                ? premiumDetails?.premium
                : selectedCarriers[lobType].premium;
          } else {
            selectedCarriers[lobType].premium = payload?.premium
              ? payload?.premium
              : selectedCarriers[lobType].premium;
          }
          yield put(updateSelectedCarriers(selectedCarriers));
        }
      }
    }
    yield put(setSfdcAMSDetailsLoader(false));
    if (!!details?.data && !isEmpty(get(details, 'data.customerData', ''))) {
      yield put(
        setSfdcAmsFormData({
          customerData: details.data.customerData,
          openPopup: true,
        })
      );
    } else if (!!details) {
      yield put(
        setSfdcAmsFormData({
          customerData: [],
          openPopup: false,
        })
      );
      if (payload) {
        yield put(setPolicyBounded({ ...policyBounded, [currentEntity]: true }));
      }
      yield put(setIsSfdcSubmitSuccess(true));
      if (payload) {
        if (!!currentEntity && currentEntity.toLowerCase() === INSURANCE_PRODUCTS[0]) {
          yield put(setHomeQuoteBind(get(quote, 'carrierId', '')));
        } else if (!!currentEntity && currentEntity.toLowerCase() === INSURANCE_PRODUCTS[2]) {
          yield put(setFloodQuoteBind(get(quote, 'carrierId', '')));
        } else if (!!currentEntity && currentEntity.toLowerCase() === EARTH_QUAKE_LOB) {
          yield put(setQuakeQuoteBind(get(quote, 'carrierId', '')));
        } else {
          yield put(setAutoQuoteBind(get(quote, 'carrierId', '')));
        }
      }
    }
  } catch (error) {
    yield put(
      setSfdcAmsFormData({
        customerData: [],
        openPopup: false,
      })
    );
    if (has(error, 'response.data.maxAttemptReached')) {
      yield put(setMaxAttemptReached(true));
    }
    yield put(setSfdcAMSError(get(error, ERROR_MESSAGE_PATH, AMS_GLOBAL_ERROR_MESSAGE)));
    yield put(errorHandler(error));
    yield put(setSfdcAMSDetailsLoader(false));
  }
}

export function* SfdcAmsDetailsSubmitWatcher() {
  yield takeEvery(AMSDetailsActions.SFDC_AMS_DETAILS_SUBMIT, sfdcAmsDetailsSubmit);
}
export default function* rootSaga() {
  yield all([
    fork(setAMSLOBChangedWatcher),
    fork(AMSDetailsSubmitWatcher),
    fork(fetchAMSDetailsWatcher),
    fork(fetchLOBSFDCDetailsWatcher),
    fork(getCarrierUnderwriterWatcher),
    fork(getCarrierUnderwriterForSfdcWatcher),
    fork(fetchLOBAMSDetailsWatcher),
    fork(fetchDataForSfdcAmsFormWatcher),
    fork(fetchBoundedPolicyDetailsWatcher),
    fork(SfdcAmsDetailsSubmitWatcher),

  ]);
}
