import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, isEqual } from 'lodash';
import {
  addNewOrganization,
  setOrgUpdatedResponseData,
  updateOrganizationData,
} from '../../redux/actions';
import Modal from '../../components/modal';
import OrganizationForm from './organization-form';
import {
  isValidUrl,
  getNestedTernaryData,
  numberRegex,
  phoneRegex,
  stringRegex,
  validatePhoneNumber,
  validateEmail,
} from '../../../utils';
import {
  ABBREVATION,
  AUTHENTICATION,
  AUTHENTICATION_OPTIONS,
  CONSUMER_POD_ID,
  HEAP_PROJECT_ID,
  INVALID,
  INVALID_URL,
  POD_ORGANIZATION_ID,
  REQUIRED,
} from '../../../constants';
import './add-edit-organization-modal.scss';

const INIT_FORM_DETAILS = {
  abbreviation: '',
  name: '',
  code: '',
  partner_id: '',
  branding_on_dale_details: {
    logo_url: '',
    template_name: '',
  },
  auth_token: '',
  status: '',
  contact: {
    email: '',
    phone: '',
  },
  authentication: '',
  visible_on_gator_ui: false,
  public_access_view_quote: false,
  feedback_enabled: false,
  consumer_token: '',
  base_url: '',
  report_enabled: false,
  gator_quoting_enabled: false,
  enable_property_info_when_prefill_failed: false,
  enable_home_quote_doc_download: false,
  hcr_org_name: '',
  heap_analytics_enabled: false,
  heap_project_id: '',
  consumer_partner_id: null,
  enable_auto_quote_doc_download: false,
  is_builder_organization: false,
  property_consumer_id: '',
};

const INIT_FORM_DETAILS_ERRORS = {
  abbreviationError: '',
  nameError: '',
  codeError: '',
  partnerError: '',
  logoUrlError: '',
  templateNameError: '',
  authTokenError: '',
  emailError: '',
  phoneError: '',
  authenticationError: '',
  statusError: '',
  usernameError: '',
  passwordError: '',
  webhookError: '',
  signatureError: '',
  commonErrorMessage: '',
  baseUrlError: '',
  heapProjectIdError: '',
};

const NUMERICAL_VALUES: { [key: string]: string } = {
  partner_id: POD_ORGANIZATION_ID?.KEY,
  heap_project_id: HEAP_PROJECT_ID?.KEY,
  consumer_partner_id: CONSUMER_POD_ID?.KEY,
};
export class AddEditOrganizationModal extends React.Component<
  AppComponents.AddEditOrganizationProps,
  AppComponents.AddEditOrganizationModalState
> {
  state: AppComponents.AddEditOrganizationModalState = {
    formDetails: { ...INIT_FORM_DETAILS },
    formDetailsErrors: {
      ...INIT_FORM_DETAILS_ERRORS,
    },
  };

  componentDidMount = () => {
    const { organizationRowData, organizations } = this.props;
    const { editOrganizationData } = organizations;
    if (
      !!organizationRowData?.id &&
      !!editOrganizationData?.id &&
      editOrganizationData?.id === organizationRowData?.id
    ) {
      this.setState((prevState: AppComponents.AddEditOrganizationModalState) => ({
        ...prevState,
        formDetails: {
          ...prevState.formDetails,
          abbreviation: !!editOrganizationData?.abbreviation
            ? editOrganizationData?.abbreviation?.toUpperCase()
            : '',
          name: editOrganizationData.name,
          code: editOrganizationData.code,
          partner_id: editOrganizationData?.partner_id,
          branding_on_dale_details: {
            logo_url: editOrganizationData?.branding_on_dale_details?.logo_url,
            template_name: editOrganizationData?.branding_on_dale_details?.template_name,
          },
          auth_token: editOrganizationData?.auth_token,
          contact: {
            email: editOrganizationData?.contact?.email,
            phone: editOrganizationData?.contact?.phone,
          },
          status: editOrganizationData?.status,
          webhook: !!editOrganizationData?.webhook ? editOrganizationData?.webhook : '',
          username: !!editOrganizationData?.username ? editOrganizationData?.username : '',
          signature: !!editOrganizationData?.signature ? editOrganizationData?.signature : '',
          password: !!editOrganizationData?.password ? editOrganizationData?.password : '',
          authentication: !!editOrganizationData?.authentication
            ? editOrganizationData?.authentication
            : '',
          visible_on_gator_ui: !!editOrganizationData?.visible_on_gator_ui,
          public_access_view_quote: !!editOrganizationData?.public_access_view_quote,
          feedback_enabled: !!editOrganizationData?.feedback_enabled,
          consumer_token: !!editOrganizationData?.consumer_token
            ? editOrganizationData?.consumer_token
            : '',
          base_url: !!editOrganizationData?.base_url ? editOrganizationData?.base_url : '',
          report_enabled: !!editOrganizationData?.report_enabled,
          gator_quoting_enabled: !!editOrganizationData?.gator_quoting_enabled,
          enable_property_info_when_prefill_failed: !!editOrganizationData?.enable_property_info_when_prefill_failed,
          enable_home_quote_doc_download: !!editOrganizationData?.enable_home_quote_doc_download,
          enable_auto_quote_doc_download: !!editOrganizationData?.enable_auto_quote_doc_download,
          is_builder_organization: !!editOrganizationData?.is_builder_organization,
          hcr_org_name: !!editOrganizationData?.hcr_org_name
            ? editOrganizationData?.hcr_org_name
            : '',
          heap_analytics_enabled: !!editOrganizationData?.heap_analytics_enabled,
          heap_project_id: !!editOrganizationData?.heap_project_id
            ? editOrganizationData?.heap_project_id
            : '',
          consumer_partner_id: editOrganizationData?.consumer_partner_id || null,
          property_consumer_id: editOrganizationData?.property_consumer_id || '',
        },
      }));
    }
  };

  UNSAFE_componentWillReceiveProps = (newProps: any) => {
    const { organizations } = newProps;
    if (!!organizations?.orgResponseData?.isUpdatedSuccessfully) {
      this.closeAddEditOrganizationModal();
      this.props.setOrgUpdatedResponseData({ isUpdatedSuccessfully: false });
    }
    if (!!organizations?.orgResponseData?.message) {
      this.setState({
        formDetailsErrors: {
          ...this.state.formDetailsErrors,
          commonErrorMessage: organizations?.orgResponseData?.message,
        },
      });
    }
  };

  validateAddEditOrganizationForm = () => {
    const { formDetails } = this.state;
    let errors = {
      ...INIT_FORM_DETAILS_ERRORS,
    };
    errors.abbreviationError =
      !isEmpty(formDetails?.abbreviation) && formDetails?.abbreviation?.trim()?.indexOf(' ') >= 0
        ? INVALID
        : '';
    errors.nameError = isEmpty(formDetails?.name?.trim()) ? REQUIRED : '';
    errors.codeError = isEmpty(formDetails?.code?.trim()) ? REQUIRED : '';
    errors.partnerError = !formDetails?.partner_id ? REQUIRED : '';
    errors.logoUrlError = isEmpty(formDetails?.branding_on_dale_details?.logo_url?.trim())
      ? REQUIRED
      : '';
    errors.templateNameError = isEmpty(formDetails?.branding_on_dale_details?.template_name)
      ? REQUIRED
      : '';
    errors.authTokenError = isEmpty(formDetails?.auth_token?.trim()) ? REQUIRED : '';
    errors.emailError = validateEmail(formDetails?.contact?.email, true);
    const validUserPhone =
      !isEmpty(formDetails?.contact?.phone?.trim()) &&
      validatePhoneNumber(formDetails?.contact?.phone);
    errors.phoneError = isEmpty(formDetails?.contact?.phone?.trim())
      ? REQUIRED
      : getNestedTernaryData(
          !phoneRegex.test(formDetails?.contact?.phone) || !validUserPhone,
          INVALID,
          ''
        );
    errors.authenticationError = isEmpty(formDetails?.authentication) ? REQUIRED : '';
    errors.statusError = isEmpty(formDetails?.status?.trim()) ? REQUIRED : '';
    errors.baseUrlError =
      !isEmpty(formDetails?.base_url?.trim()) && !isValidUrl(formDetails?.base_url)
        ? INVALID_URL
        : '';
    errors.heapProjectIdError =
      !!formDetails?.heap_analytics_enabled && isEmpty(formDetails?.heap_project_id?.trim())
        ? REQUIRED
        : getNestedTernaryData(
            !!formDetails?.heap_analytics_enabled &&
              !isEmpty(formDetails?.heap_project_id?.trim()) &&
              parseInt(formDetails?.heap_project_id?.trim()) < 1,
            INVALID,
            ''
          );
    if (
      !isEmpty(formDetails?.authentication) &&
      formDetails?.authentication === AUTHENTICATION_OPTIONS[1]
    ) {
      errors.usernameError = isEmpty(formDetails?.username?.trim()) ? REQUIRED : '';
      errors.passwordError = isEmpty(formDetails?.password)
        ? REQUIRED
        : getNestedTernaryData(isEmpty(formDetails?.password?.trim()), INVALID, '');
      errors.webhookError = isEmpty(formDetails?.webhook?.trim()) ? REQUIRED : '';
      errors.signatureError = isEmpty(formDetails?.signature?.trim()) ? REQUIRED : '';
    }
    this.setState({
      formDetailsErrors: {
        ...errors,
        commonErrorMessage: '',
      },
    });
    if (!isEqual(errors, INIT_FORM_DETAILS_ERRORS)) {
      return false;
    } else {
      return true;
    }
  };

  closeAddEditOrganizationModal = () => {
    this.props.onClose();
  };

  handleOnChange = (event: any) => {
    const { name, value } = event.target;
    const { organizationRowData } = this.props;
    let numberValue: any;
    if (NUMERICAL_VALUES[name] && value.match(numberRegex) == null) {
      return;
    }
    if (name === AUTHENTICATION) {
      if (
        value === AUTHENTICATION_OPTIONS[1] &&
        isEmpty(organizationRowData?.username) &&
        isEmpty(organizationRowData?.password) &&
        isEmpty(organizationRowData?.webhook) &&
        isEmpty(organizationRowData?.signature)
      ) {
        this.setState((prevState: AppComponents.AddEditOrganizationModalState) => ({
          ...prevState,
          formDetails: {
            ...prevState.formDetails,
            username: '',
            password: '',
            webhook: '',
            signature: '',
          },
        }));
      }
    }
    if (!!name && name === ABBREVATION && !isEmpty(value) && !stringRegex.test(value)) {
      return;
    }
    this.setState((prevState: AppComponents.AddEditOrganizationModalState) => ({
      ...prevState,
      formDetails: {
        ...prevState.formDetails,
        [name]: numberValue ? numberValue : value,
      },
    }));
  };

  handleContactOnChage = (event: any) => {
    const { name, value } = event.target;
    this.setState((prevState: AppComponents.AddEditOrganizationModalState) => ({
      ...prevState,
      formDetails: {
        ...prevState.formDetails,
        contact: {
          ...prevState.formDetails.contact,
          [name]: value,
        },
      },
    }));
  };

  handleBrandingOnChange = (event: any) => {
    const { name, value } = event.target;

    this.setState((prevState: AppComponents.AddEditOrganizationModalState) => ({
      ...prevState,
      formDetails: {
        ...prevState.formDetails,
        branding_on_dale_details: {
          ...prevState.formDetails.branding_on_dale_details,
          [name]: value,
        },
      },
    }));
  };

  setDefaultAuthentication = (value: any) => {
    this.setState((prevState: AppComponents.AddEditOrganizationModalState) => ({
      ...prevState,
      formDetails: {
        ...prevState.formDetails,
        authentication: value,
      },
    }));
  };

  setDefaultTheme = (value: any) => {
    this.setState((prevState: AppComponents.AddEditOrganizationModalState) => ({
      ...prevState,
      formDetails: {
        ...prevState.formDetails,
        branding_on_dale_details: {
          ...prevState.formDetails.branding_on_dale_details,
          template_name: value,
        },
      },
    }));
  };

  setDefaultStatus = (value: string) => {
    this.setState((prevState: AppComponents.AddEditOrganizationModalState) => ({
      ...prevState,
      formDetails: {
        ...prevState.formDetails,
        status: value,
      },
    }));
  };

  onSave = () => {
    const { formDetails } = this.state;
    const { organizationRowData } = this.props;
    this.props.setOrgUpdatedResponseData({ message: '' });
    const isValid = this.validateAddEditOrganizationForm();
    if (!!isValid) {
      let payload = {
        abbreviation: !!formDetails?.abbreviation
          ? formDetails?.abbreviation?.trim()?.toUpperCase()
          : null,
        name: formDetails.name.trim(),
        code: formDetails.code.trim(),
        partner_id: formDetails.partner_id,
        branding_on_dale_details: {
          logo_url: formDetails.branding_on_dale_details.logo_url.trim(),
          template_name: formDetails.branding_on_dale_details.template_name.trim(),
        },
        auth_token: formDetails.auth_token.trim(),
        status: formDetails.status.trim(),
        contact: {
          email: formDetails.contact.email.trim(),
          phone: formDetails.contact.phone.trim(),
        },
        authentication: formDetails.authentication,
        username: formDetails.username,
        password: formDetails.password,
        webhook: formDetails.webhook,
        signature: formDetails.signature,
        visible_on_gator_ui: !!formDetails?.visible_on_gator_ui,
        public_access_view_quote: !!formDetails?.public_access_view_quote,
        feedback_enabled: !!formDetails?.feedback_enabled,
        consumer_token: !isEmpty(formDetails?.consumer_token)
          ? formDetails?.consumer_token?.trim()
          : '',
        base_url: !isEmpty(formDetails?.base_url) ? formDetails?.base_url?.trim() : '',
        report_enabled: !!formDetails?.report_enabled,
        gator_quoting_enabled: !!formDetails?.gator_quoting_enabled,
        enable_property_info_when_prefill_failed: !!formDetails?.enable_property_info_when_prefill_failed,
        enable_home_quote_doc_download: !!formDetails?.enable_home_quote_doc_download,
        enable_auto_quote_doc_download: formDetails?.enable_auto_quote_doc_download,
        is_builder_organization: formDetails?.is_builder_organization,
        hcr_org_name: !isEmpty(formDetails?.hcr_org_name) ? formDetails?.hcr_org_name?.trim() : '',
        heap_analytics_enabled: !!formDetails?.heap_analytics_enabled,
        heap_project_id:
          !isEmpty(formDetails?.heap_project_id) && !!formDetails?.heap_analytics_enabled
            ? formDetails?.heap_project_id?.trim()
            : organizationRowData?.heap_project_id || '',
        consumer_partner_id: !!formDetails?.consumer_partner_id
          ? parseInt(formDetails?.consumer_partner_id)
          : null,
        property_consumer_id: formDetails?.property_consumer_id?.trim() || '',
      };
      if (formDetails.authentication !== AUTHENTICATION_OPTIONS[1]) {
        if (
          !!organizationRowData?.username &&
          !!organizationRowData?.password &&
          !!organizationRowData?.webhook &&
          !!organizationRowData?.signature
        ) {
          payload.username = null;
          payload.password = null;
          payload.webhook = null;
          payload.signature = null;
        } else {
          delete payload.username;
          delete payload.password;
          delete payload.webhook;
          delete payload.signature;
        }
      }
      if (organizationRowData) {
        if (!isEmpty(organizationRowData.id)) {
          const organizationUpdated = { ...payload, id: organizationRowData.id };
          this.props.updateOrganizationData(organizationUpdated);
        }
      } else {
        this.props.addNewOrganization(payload);
      }
    }
  };

  onCheckBoxChanges = (event: any) => {
    const { name, checked } = event.target;
    this.setState((prevState: AppComponents.AddEditOrganizationModalState) => ({
      ...prevState,
      formDetails: {
        ...prevState.formDetails,
        [name]: checked,
      },
    }));
  };

  render() {
    const { orgTitle, organizations } = this.props;
    const { formDetails, formDetailsErrors } = this.state;
    const {
      handleOnChange,
      handleContactOnChage,
      handleBrandingOnChange,
      setDefaultAuthentication,
      setDefaultTheme,
      setDefaultStatus,
      onSave,
      onCheckBoxChanges,
    } = this;
    return (
      <Modal
        isOpen={true}
        onClose={this.closeAddEditOrganizationModal}
        title={orgTitle}
        navButtons
        maxWidth='md'
        onSave={onSave}
        loader={organizations?.addEditOrgLoader}
      >
        {formDetails && (
          <OrganizationForm
            formDetails={formDetails}
            handleOnChange={handleOnChange}
            handleContactOnChage={handleContactOnChage}
            handleBrandingOnChange={handleBrandingOnChange}
            setDefaultAuthentication={setDefaultAuthentication}
            setDefaultTheme={setDefaultTheme}
            setDefaultStatus={setDefaultStatus}
            errors={formDetailsErrors}
            loader={organizations?.addEditOrgLoader}
            onCheckBoxChanges={onCheckBoxChanges}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = ({ organizations }: AppComponents.AddEditOrganizationModalStore) => {
  return { organizations };
};

const mapDispatchToProps = (dispatch: any): AppComponents.AddEditOrganizationModalDispatch => {
  return bindActionCreators(
    {
      addNewOrganization,
      setOrgUpdatedResponseData,
      updateOrganizationData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditOrganizationModal);
