import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty, map, startCase } from 'lodash';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputBase,
  InputLabel,
  Switch,
  Typography,
} from '@material-ui/core';
import {
  CheckCircle,
  KeyboardBackspace,
  RadioButtonUnchecked,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import AddEditOrganizationModal from '../add-edit-organization-modal';
import { withStyles } from '@material-ui/core/styles';
import Tabs from './Tabs';
import Modal from '../../components/modal';
import AmsComponent from '../ams';
import CarrierAppointmentsTable from '../carrier-appointments-table';
import CardSkeleton from '../../components/card-skeleton';
import ViewCrmDetails from '../crm/view-crm-details';
import CRMComponent from '../crm';
import EoiSettingsTable from '../eoi-settings';
import LenderSetting from '../lender-settings';
import history from '../../../history';
import { setOrgAccountsListData, updateOrganizationData } from '../../redux/actions';
import { getThemeName, getNestedTernaryData, productList } from '../../../utils';
import AMSButtonImg from '../../../assets/images/ams-button.png';
import AutoIcon from '../../../assets/images/auto.svg';
import CyberSecurityIcon from '../../../assets/images/Cyber.svg';
import EarthquakeIcon from '../../../assets/images/EarthquakeIcon.svg';
import FloodIcon from '../../../assets/images/flood-green.svg';
import HomeIcon from '../../../assets/images/home.svg';
import HomeWarrantyIcon from '../../../assets/images/Home Warranty.svg';
import LifeIcon from '../../../assets/images/Life.svg';
import PetIcon from '../../../assets/images/PetIcon-green.svg';
import UmbrellaIcon from '../../../assets/images/umbrella-green.svg';
import {
  ADMIN_ROUTE,
  ALLOW_ADHOC_BIND,
  ALLOW_AMS_FOR_GATOR,
  ALLOW_AMS_FOR_SALESFORCE,
  ALLOW_DOWNLOAD_QUOTE,
  ALLOW_SYNC_INTEGRATION,
  ASTERISK,
  AUTHENTICATION_OPTIONS,
  USE_EXTERNAL_APPOINTMENTS,
  CARRIER_TIMEOUT_MESSAGE_ENABLED,
  CONSUMER_POD_ID,
  CONSUMER_TOKEN,
  CYBER_SECURITY_LOB,
  DOWNLOAD_QUOTE_PREFIX,
  EARTH_QUAKE_LOB,
  EDIT,
  ENCRYPTED_DATA,
  EOI_SETTINGS,
  FEEDBACK_BUTTON,
  HCR_ASYNCHRONOUS_RESPONSE_LABEL,
  HCR_IFRAME_CLIENT_KEY,
  HCR_IFRAME_CLIENT_LABEL,
  HCR_ORGANIZATION,
  HEAP_ANALYTICS,
  HEAP_PROJECT_ID,
  HIPPO_RATE_COMPARE,
  HOME_WARRANTY_LOB,
  HTML_EM,
  IN_ACTIVE,
  INCLUDE_GATOR_QUOTING,
  INCLUDE_IN_REPORT,
  INSURANCE_BUSINESS_TYPES,
  JV_HOME_URL,
  LENDER_SETTING,
  LOB_LIST,
  MAX_API_RESPONSE_TIME,
  MAX_API_RESPONSE_TIME_ERROR,
  MAX_API_RESPONSE_TIME_IN_SEC,
  NO,
  NUMBER,
  NUMBER_1,
  NUMBER_4,
  NUMBER_5,
  NUMBER_60,
  NUMBER_99,
  NUMBER_ZERO,
  PRODUCT_CHOOSE_ERROR,
  REQUIRED,
  SELECT,
  SFDC_KEY,
  TOTAL_NO_OF_QUOTES_FETCH_ERR,
  XS,
  YES,
  BUILDER_ORGANIZATION,
} from '../../../constants';
import './organization-editor.scss';
import HcrIframeClient from '../hcr-iframe-client';
import { PROPERTY_CONSUMER_ID } from '../../../constants/organization';

export const CustomSwitch = withStyles({
  switchBase: {
    color: '#ccc',
    '&$checked': {
      color: '#00cf83',
    },
    '&$checked + $track': {
      backgroundColor: '#00cf83',
    },
  },
  checked: {},
  track: {},
})(Switch);

const INIT_RATE_SETTINGS_DETAILS_STATE = {
  hippo_only: false,
  total_number_of_quotes: NUMBER_4,
  check_agent_license_compliance: false,
  show_quote_if_wind_is_excluded: false,
  comparative_results_page: false,
  hippo_rate_compare: false,
  rater_page_type: '',
  is_verisk_prefill_available: false,
  allow_async_integration: false,
  max_api_response_time: NUMBER_5,
  use_external_appointments: false,
  carrier_timeout_message_enabled: false,
};

const INIT_LOB_DETAILS_STATE: { [products: string]: { [products: string]: object } } = {
  lob: {},
};

const INIT_ERROR_DETAILS_STATE = {
  display_errors_on_ui: false,
  customized_error_page_details: {
    error_text: '',
    error_logo_url: '',
  },
};

const INIT_RATE_SETTINGS_DETAILS_ERROR = {
  totalNumberOfQuotesErr: '',
  maxApiResponseTimeErr: '',
};

const INIT_ERROR_DETAILS_ERROR = {
  errorText: '',
  errorLogoUrl: '',
};

const INIT_LOB_ERROR = {
  productError: '',
};

const OrganizationEditor = (props: AppComponents.OrganizationEditorProps) => {
  const { editOrganizationData, organization, organizations, organizationEdit } = props;
  const [isEditAMSData, setIsEditAMSData] = useState(false);
  const [modalSelected, setModalSelected] = useState('');
  const [rateSettingsDetails, setRateSettingsDetails] =
    useState<AppComponents.RateSettingsDetailsState>(INIT_RATE_SETTINGS_DETAILS_STATE);
  const [rateSettingsErrors, setRateSettingsErrors] = useState(INIT_RATE_SETTINGS_DETAILS_ERROR);
  const [LOBDetails, setLOBDetails] = useState<any>(INIT_LOB_DETAILS_STATE);
  const [errorDetails, setErrorDetails] = useState(INIT_ERROR_DETAILS_STATE);
  const [errorErrors, setErrorErrors] = useState(INIT_ERROR_DETAILS_ERROR);
  const [lobErrors, setLobErrors] = useState(INIT_LOB_ERROR);
  const [isEditCrm, setIsEditCrm] = useState(false);
  const [isEditLenderSetting, setIsEditLenderSetting] = useState(false);
  const [isShowPODAuthToken, setIsShowPODAuthToken] = useState(false);
  const [isShowConsumerToken, setIsShowConsumerToken] = useState(false);

  const ProductIcons: any = {
    home: HomeIcon,
    auto: AutoIcon,
    flood: FloodIcon,
    pet: PetIcon,
    earthquake: EarthquakeIcon,
    life: LifeIcon,
    'home-warranty': HomeWarrantyIcon,
    'cyber-security': CyberSecurityIcon,
    umbrella: UmbrellaIcon,
  };

  const homeKey = 'HOME';
  const getPropertyValue = (value: any, propValue: any) => (propValue ? propValue : value);

  const initRateSettingsDetails = () => {
    const {
      hippo_only,
      total_number_of_quotes,
      show_quote_if_wind_is_excluded,
      comparative_results_page,
      check_agent_license_compliance,
      hippo_rate_compare,
      is_verisk_prefill_available,
      max_api_response_time,
      use_external_appointments,
      carrier_timeout_message_enabled,
    } = INIT_RATE_SETTINGS_DETAILS_STATE;

    setRateSettingsDetails({
      hippo_only: getPropertyValue(hippo_only, organization?.hippo_only),
      total_number_of_quotes: getPropertyValue(
        total_number_of_quotes,
        organization?.total_number_of_quotes
      ),
      check_agent_license_compliance: getPropertyValue(
        check_agent_license_compliance,
        organization?.check_agent_license_compliance
      ),
      show_quote_if_wind_is_excluded: getPropertyValue(
        show_quote_if_wind_is_excluded,
        organization?.show_quote_if_wind_is_excluded
      ),
      comparative_results_page:
        !!organization?.rater_page_type &&
        organization?.rater_page_type?.toLowerCase() === 'navigation'
          ? true
          : getNestedTernaryData(!!comparative_results_page, true, false),
      rater_page_type: !!organization?.rater_page_type
        ? organization?.rater_page_type
        : getNestedTernaryData(!!comparative_results_page, 'navigation', 'carousel'),
      hippo_rate_compare: getPropertyValue(hippo_rate_compare, organization?.hippo_rate_compare),
      is_verisk_prefill_available:
        !!organization?.is_verisk_prefill_available || !!is_verisk_prefill_available ? true : false,
      max_api_response_time: !!organization?.max_api_response_time
        ? organization?.max_api_response_time
        : max_api_response_time,
      allow_async_integration: !!organization?.allow_async_integration ? true : false,
      hcr_iframe_client_enabled: !!organization?.hcr_iframe_client_enabled,
      use_external_appointments: getPropertyValue(use_external_appointments, organization?.use_external_appointments),
      carrier_timeout_message_enabled: getPropertyValue(carrier_timeout_message_enabled, organization?.carrier_timeout_message_enabled)
    });
  };

  const initErrorDetails = () => {
    const { display_errors_on_ui, customized_error_page_details } = INIT_ERROR_DETAILS_STATE;
    const { error_text, error_logo_url } = customized_error_page_details;
    setErrorDetails({
      display_errors_on_ui: getPropertyValue(
        display_errors_on_ui,
        organization?.display_errors_on_ui
      ),
      customized_error_page_details: {
        error_text: getPropertyValue(
          error_text,
          organization?.customized_error_page_details?.error_text
        ),
        error_logo_url: getPropertyValue(
          error_logo_url,
          organization?.customized_error_page_details?.error_logo_url
        ),
      },
    });
  };

  const initLOBDetails = () => {
    const { lob } = INIT_LOB_DETAILS_STATE;
    setLOBDetails({
      lob: getPropertyValue(lob, organization?.lob),
    });
  };

  const closeModal = (initForm: any = null) => {
    if (typeof initForm === 'function') {
      initForm();
    }
    setModalSelected('');
    setIsEditAMSData(false);
    setIsEditCrm(false);
    initLOBDetails();
    setRateSettingsErrors({ ...INIT_RATE_SETTINGS_DETAILS_ERROR });
    setLobErrors({ ...INIT_LOB_ERROR });
    setErrorErrors({ ...INIT_ERROR_DETAILS_ERROR });
    setIsEditLenderSetting(false);
    if (
      !!editOrganizationData?.crm_account_id &&
      editOrganizationData?.crm_account_name?.toLowerCase() !== SELECT
    ) {
      props.setOrgAccountsListData([
        { value: '', label: startCase(SELECT) },
        {
          value: editOrganizationData?.crm_account_id || '',
          label: editOrganizationData?.crm_account_name || '',
        },
      ]);
    } else {
      props.setOrgAccountsListData([{ value: '', label: startCase(SELECT) }]);
    }
  };

  useEffect(() => {
    if (!organizationEdit && !organizations?.getOrganizationLoader) {
      initRateSettingsDetails();
      initLOBDetails();
      initErrorDetails();
    }
    // eslint-disable-next-line
  }, [organizationEdit, organizations?.getOrganizationLoader]);

  useEffect(() => {
    if (!!organizations?.orgResponseData?.isUpdatedSuccessfully) {
      closeModal();
    }
    // eslint-disable-next-line
  }, [organizations?.orgResponseData?.isUpdatedSuccessfully]);

  const validateRateRateSettings = () => {
    let errors = { ...INIT_RATE_SETTINGS_DETAILS_ERROR };
    errors.totalNumberOfQuotesErr = !rateSettingsDetails?.total_number_of_quotes
      ? REQUIRED
      : getNestedTernaryData(
          rateSettingsDetails?.total_number_of_quotes < NUMBER_1 ||
            rateSettingsDetails?.total_number_of_quotes > NUMBER_99,
          TOTAL_NO_OF_QUOTES_FETCH_ERR,
          ''
        );
    errors.maxApiResponseTimeErr =
      !!rateSettingsDetails?.hippo_rate_compare && !rateSettingsDetails?.max_api_response_time
        ? REQUIRED
        : getNestedTernaryData(
            !!rateSettingsDetails?.hippo_rate_compare &&
              (rateSettingsDetails?.max_api_response_time < NUMBER_5 ||
                rateSettingsDetails?.max_api_response_time > NUMBER_60),
            MAX_API_RESPONSE_TIME_ERROR,
            ''
          );
    setRateSettingsErrors({
      ...rateSettingsErrors,
      ...errors,
    });
    if (!!errors?.totalNumberOfQuotesErr || !!errors?.maxApiResponseTimeErr) {
      return false;
    } else {
      if (!rateSettingsDetails?.hippo_rate_compare && !organization?.hippo_rate_compare) {
        delete rateSettingsDetails.max_api_response_time;
      }
      delete rateSettingsDetails.comparative_results_page;
      return true;
    }
  };

  const validateError = () => {
    let errors = { ...INIT_ERROR_DETAILS_ERROR };
    let isErrorVisibleOnUI: boolean = !!errorDetails?.display_errors_on_ui || false;
    errors.errorText =
      isEmpty(errorDetails?.customized_error_page_details?.error_text?.trim()) && isErrorVisibleOnUI
        ? REQUIRED
        : '';
    errors.errorLogoUrl =
      isEmpty(errorDetails?.customized_error_page_details?.error_logo_url?.trim()) &&
      isErrorVisibleOnUI
        ? REQUIRED
        : '';
    setErrorErrors({
      ...errorErrors,
      ...errors,
    });

    if (!!errors?.errorText || !!errors?.errorLogoUrl) {
      return false;
    } else {
      return true;
    }
  };

  const validateLobDetails = () => {
    let productErr = isEmpty(LOBDetails?.lob) ? PRODUCT_CHOOSE_ERROR : '';
    setLobErrors({
      productError: productErr,
    });
    if (!isEmpty(productErr)) {
      return false;
    } else {
      return true;
    }
  };

  const updateData = (data: any, validate: any) => {
    if (validate()) {
      if (
        !isEmpty(organization?.id) &&
        !isEmpty(organization?.name) &&
        !isEmpty(organization?.code) &&
        !isEmpty(organization?.auth_token)
      ) {
        props.updateOrganizationData({
          ...data,
          id: organization.id,
          name: organization.name,
          code: organization.code,
          auth_token: organization.auth_token,
        });
      }
    }
  };

  const handleOnChangeRateSettingsCheck = (event: any) => {
    const { name, checked } = event.target;
    if (name === 'comparative_results_page') {
      setRateSettingsDetails((prevState: any) => ({
        ...prevState,
        [name]: checked,
        rater_page_type: !!checked ? 'navigation' : 'carousel',
      }));
    } else if (name === HIPPO_RATE_COMPARE) {
      setRateSettingsErrors({
        ...rateSettingsErrors,
        maxApiResponseTimeErr: '',
      });
      setRateSettingsDetails((prevState: any) => ({
        ...prevState,
        [name]: checked,
        max_api_response_time: !!checked ? NUMBER_5 : NUMBER_ZERO,
      }));
    } else {
      setRateSettingsDetails((prevState: any) => ({
        ...prevState,
        [name]: checked,
      }));
    }
  };

  const handleOnChangeRateSettingsInput = (event: any) => {
    const { name, value } = event.target;
    const valueToNumber = parseInt(value);
    if (
      (name !== MAX_API_RESPONSE_TIME && !!valueToNumber && valueToNumber > NUMBER_99) ||
      (name === MAX_API_RESPONSE_TIME && !!valueToNumber && valueToNumber > NUMBER_60) ||
      valueToNumber <= NUMBER_ZERO
    ) {
      return;
    }
    setRateSettingsErrors({
      totalNumberOfQuotesErr:
        name !== MAX_API_RESPONSE_TIME ? '' : rateSettingsErrors?.totalNumberOfQuotesErr,
      maxApiResponseTimeErr:
        name === MAX_API_RESPONSE_TIME ? '' : rateSettingsErrors?.maxApiResponseTimeErr,
    });
    setRateSettingsDetails((prevState: any) => ({
      ...prevState,
      [name]: valueToNumber,
    }));
  };

  const checkProduct = (object: any, product: any) => {
    if (object) {
      return object[product?.toUpperCase()];
    }
    return false;
  };

  const checkProductHome = (object: any, product: any) => {
    if (!!object && !!product) {
      return object[product];
    }
    return false;
  };

  const handleOnChangeLOBDetails = (product: any) => {
    if (checkProduct(LOBDetails.lob, product)) {
      let { [product?.toUpperCase()]: omit, ...lobData } = !!LOBDetails?.lob && LOBDetails?.lob;
      setLOBDetails({ lob: lobData });
    } else {
      let newProduct = {};
      if (product === LOB_LIST[0]) {
        newProduct = {
          [product?.toUpperCase()]: {
            HO3: true,
            HO6: true,
          },
        };
      } else {
        newProduct = {
          [product?.toUpperCase()]: {},
        };
      }
      setLOBDetails((prevState: any) => ({
        lob: { ...prevState?.lob, ...newProduct },
      }));
    }
  };

  const handleOnChangeProductHome = (object: any, product: any) => {
    let newProduct: any = null;
    if (checkProductHome(object, product)) {
      let homeLob = LOBDetails?.lob[homeKey];
      let { [product]: omit, ...lobData } = !!homeLob && homeLob;
      if (!isEmpty(lobData)) {
        newProduct = {
          [homeKey]: lobData,
        };
        setLOBDetails((prevState: any) => ({
          lob: { ...prevState.lob, ...newProduct },
        }));
      } else {
        let { [homeKey]: omitData, ...lobDetails } = !!LOBDetails?.lob && LOBDetails?.lob;
        setLOBDetails({ lob: lobDetails });
      }
    } else {
      newProduct = {
        [homeKey]: {
          ...LOBDetails?.lob[homeKey],
          [product]: true,
        },
      };
      setLOBDetails((prevState: any) => ({
        lob: { ...prevState.lob, ...newProduct },
      }));
    }
  };

  const handleOnChangeErrorCheck = (event: any) => {
    const { name, checked } = event.target;
    setErrorDetails((prevState: any) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleOnChangeErrorInput = (event: any) => {
    const { name, value } = event.target;
    setErrorDetails((prevState: any) => ({
      ...prevState,
      customized_error_page_details: {
        ...prevState.customized_error_page_details,
        [name]: value,
      },
    }));
  };

  const rateSettingsForm = (disabled: boolean = true) => (
    <form className='form org-editor'>
      <div className='row mb-2 form-item'>
        <InputLabel className='form-item__label col-10' htmlFor='hippo_only'>
          Hippo Quotes Only{disabled && ':'}
        </InputLabel>
        <span className='col-2 p-0 d-flex justify-content-center'>
          <CustomSwitch
            checked={disabled ? !!organization?.hippo_only : !!rateSettingsDetails?.hippo_only}
            onChange={handleOnChangeRateSettingsCheck}
            name='hippo_only'
            disabled={disabled || organizations?.addEditOrgLoader}
          />
        </span>
      </div>
      <div className='row mb-2 form-item'>
        <InputLabel className='form-item__label col-10' htmlFor='total_number_of_quotes'>
          Total Number Of Quotes Fetch{disabled && ':'}
        </InputLabel>
        <div className='col-2 p-0 d-flex justify-content-center quote-fetch-input'>
          <div className='d-block'>
            <InputBase
              inputProps={{
                name: 'total_number_of_quotes',
                id: 'total_number_of_quotes',
                type: 'number',
                min: '1',
                max: '99',
              }}
              className='form-item__input grey-border'
              value={getNestedTernaryData(
                disabled,
                getNestedTernaryData(
                  !!organization?.total_number_of_quotes,
                  organization?.total_number_of_quotes,
                  NUMBER_4
                ),
                rateSettingsDetails.total_number_of_quotes
              )}
              onChange={handleOnChangeRateSettingsInput}
              required
              disabled={disabled || organizations?.addEditOrgLoader}
            />
            {!disabled && (
              <FormHelperText className='text-red text-center'>
                {rateSettingsErrors?.totalNumberOfQuotesErr}
              </FormHelperText>
            )}
          </div>
        </div>
      </div>
      <div className='row mb-2 form-item'>
        <InputLabel className='form-item__label col-10' htmlFor='check_agent_license_compliance'>
          Check Agent License Compliance{disabled && ':'}
        </InputLabel>
        <span className='col-2 p-0 d-flex justify-content-center'>
          <CustomSwitch
            checked={
              disabled
                ? !!organization?.check_agent_license_compliance
                : !!rateSettingsDetails?.check_agent_license_compliance
            }
            onChange={handleOnChangeRateSettingsCheck}
            name='check_agent_license_compliance'
            disabled={disabled || organizations?.addEditOrgLoader}
          />
        </span>
      </div>
      <div className='row mb-2 form-item'>
        <InputLabel className='form-item__label col-10' htmlFor='show_quote_if_wind_is_excluded'>
          Show Quote If Wind Is Excluded{disabled && ':'}
        </InputLabel>
        <span className='col-2 p-0 d-flex justify-content-center'>
          <CustomSwitch
            checked={
              disabled
                ? !!organization?.show_quote_if_wind_is_excluded
                : !!rateSettingsDetails?.show_quote_if_wind_is_excluded
            }
            onChange={handleOnChangeRateSettingsCheck}
            name='show_quote_if_wind_is_excluded'
            disabled={disabled || organizations?.addEditOrgLoader}
          />
        </span>
      </div>

      <div className='row mb-2 form-item'>
        <InputLabel className='form-item__label col-10' htmlFor='comparative_results_page'>
          Comparative Results Page{disabled && ':'}
        </InputLabel>
        <span className='col-2 p-0 d-flex justify-content-center'>
          <CustomSwitch
            checked={getNestedTernaryData(
              disabled,
              getNestedTernaryData(
                !!organization?.rater_page_type &&
                  organization?.rater_page_type?.toLowerCase() === 'navigation',
                true,
                false
              ),
              getNestedTernaryData(
                !!rateSettingsDetails?.comparative_results_page ||
                  (!!rateSettingsDetails?.rater_page_type &&
                    rateSettingsDetails?.rater_page_type?.toLowerCase() === 'navigation'),
                true,
                false
              )
            )}
            onChange={handleOnChangeRateSettingsCheck}
            name='comparative_results_page'
            disabled={disabled || organizations?.addEditOrgLoader}
          />
        </span>
      </div>
      <div className='row mb-2 form-item'>
        <InputLabel className='form-item__label col-10' htmlFor='is_verisk_prefill_available'>
          Auto Prefill{disabled && ':'}
        </InputLabel>
        <span className='col-2 p-0 d-flex justify-content-center'>
          <CustomSwitch
            checked={
              disabled
                ? !!organization?.is_verisk_prefill_available
                : !!rateSettingsDetails?.is_verisk_prefill_available
            }
            onChange={handleOnChangeRateSettingsCheck}
            name='is_verisk_prefill_available'
            disabled={disabled || organizations?.addEditOrgLoader}
          />
        </span>
      </div>
      <div className='row mb-2 form-item'>
        <InputLabel className='form-item__label col-10' htmlFor='hippo_rate_compare'>
          Hippo Comparative Rater{disabled && ':'}
        </InputLabel>
        <span className='col-2 p-0 d-flex justify-content-center'>
          <CustomSwitch
            checked={
              disabled ? !!organization?.hippo_rate_compare : !!rateSettingsDetails?.hippo_rate_compare
            }
            onChange={handleOnChangeRateSettingsCheck}
            name='hippo_rate_compare'
            disabled={disabled || organizations?.addEditOrgLoader}
          />
        </span>
      </div>

      <div className='row mb-2 form-item'>
        <InputLabel className='form-item__label col-10' htmlFor={HCR_IFRAME_CLIENT_KEY}>
          {HCR_IFRAME_CLIENT_LABEL}
          {disabled && ':'}
        </InputLabel>
        <span className='col-2 p-0 d-flex justify-content-center'>
          <CustomSwitch
            checked={
              disabled
                ? !!organization?.hcr_iframe_client_enabled
                : !!rateSettingsDetails?.hcr_iframe_client_enabled
            }
            onChange={handleOnChangeRateSettingsCheck}
            name={HCR_IFRAME_CLIENT_KEY}
            disabled={disabled || organizations?.addEditOrgLoader}
          />
        </span>
      </div>

      {((!disabled && !!rateSettingsDetails?.hippo_rate_compare) ||
        (!!disabled && !!organization?.hippo_rate_compare)) && (
        <>
          <div className='row mb-2 form-item'>
            <InputLabel className='form-item__label col-10' htmlFor={ALLOW_SYNC_INTEGRATION}>
              {HCR_ASYNCHRONOUS_RESPONSE_LABEL}
              {disabled && ':'}
            </InputLabel>
            <span className='col-2 p-0 d-flex justify-content-center'>
              <CustomSwitch
                checked={
                  disabled
                    ? !!organization?.allow_async_integration
                    : !!rateSettingsDetails?.allow_async_integration
                }
                onChange={handleOnChangeRateSettingsCheck}
                name={ALLOW_SYNC_INTEGRATION}
                disabled={disabled || organizations?.addEditOrgLoader}
              />
            </span>
          </div>
          <div className='row mb-2 form-item'>
            <InputLabel className='form-item__label col-10' htmlFor={MAX_API_RESPONSE_TIME}>
              {MAX_API_RESPONSE_TIME_IN_SEC}
              {disabled && ':'}
            </InputLabel>
            <div className='col-2 p-0 d-flex justify-content-center quote-fetch-input'>
              <div className='d-block'>
                <InputBase
                  inputProps={{
                    name: MAX_API_RESPONSE_TIME,
                    id: MAX_API_RESPONSE_TIME,
                    type: NUMBER,
                    min: NUMBER_5,
                    max: NUMBER_60,
                  }}
                  className='form-item__input grey-border'
                  value={getNestedTernaryData(
                    disabled,
                    getNestedTernaryData(
                      !!organization?.max_api_response_time,
                      organization?.max_api_response_time,
                      NUMBER_5
                    ),
                    rateSettingsDetails.max_api_response_time
                  )}
                  onChange={handleOnChangeRateSettingsInput}
                  required
                  disabled={disabled || organizations?.addEditOrgLoader}
                />
              </div>
            </div>
            {!disabled && (
              <FormHelperText className='text-red text-center'>
                {rateSettingsErrors?.maxApiResponseTimeErr}
              </FormHelperText>
            )}
          </div>
        </>
      )}
      <div className='row mb-2 form-item'>
        <InputLabel className='form-item__label col-10' htmlFor={USE_EXTERNAL_APPOINTMENTS.key}>
          {USE_EXTERNAL_APPOINTMENTS.label}{disabled && ':'}
        </InputLabel>
        <span className='col-2 p-0 d-flex justify-content-center'>
          <CustomSwitch
            checked={
              disabled ? !!organization?.use_external_appointments : !!rateSettingsDetails?.use_external_appointments
            }
            onChange={handleOnChangeRateSettingsCheck}
            name={USE_EXTERNAL_APPOINTMENTS.key}
            disabled={disabled || organizations?.addEditOrgLoader}
          />
        </span>
      </div>
      <div className='row mb-2 form-item'>
        <InputLabel className='form-item__label col-10' htmlFor={CARRIER_TIMEOUT_MESSAGE_ENABLED.key}>
          {CARRIER_TIMEOUT_MESSAGE_ENABLED.label}{disabled && ':'}
        </InputLabel>
        <span className='col-2 p-0 d-flex justify-content-center'>
          <CustomSwitch
            checked={
              disabled ? !!organization?.carrier_timeout_message_enabled : !!rateSettingsDetails?.carrier_timeout_message_enabled
            }
            onChange={handleOnChangeRateSettingsCheck}
            name={CARRIER_TIMEOUT_MESSAGE_ENABLED.key}
            disabled={disabled || organizations?.addEditOrgLoader}
          />
        </span>
      </div>
    </form>
  );

  const lobDetailsForm = (disabled: boolean = true) => (
    <>
      <div className='lob-modal'>
        <Grid container spacing={2} className='row lob-wrap-div mb-10'>
          {map(LOB_LIST, (product: any) => (
            <>
              {product === LOB_LIST[0] ? (
                <>
                  <Grid item xs={4} key={product}>
                    <Button
                      variant='outlined'
                      className={`product-item h-100 ${getNestedTernaryData(
                        disabled,
                        getNestedTernaryData(
                          !!organization?.lob && !!checkProduct(organization?.lob, product),
                          'active',
                          ''
                        ),
                        getNestedTernaryData(
                          !!LOBDetails?.lob && !!checkProduct(LOBDetails?.lob, product),
                          'active',
                          ''
                        )
                      )}`}
                      disableRipple
                      onClick={() => handleOnChangeLOBDetails(product)}
                      disabled={disabled || organizations?.addEditOrgLoader}
                    >
                      <FormControlLabel
                        className='button-icon'
                        control={
                          <Checkbox
                            icon={<RadioButtonUnchecked />}
                            name={product}
                            checkedIcon={<CheckCircle />}
                            checked={getNestedTernaryData(
                              disabled,
                              getNestedTernaryData(
                                !!organization?.lob,
                                !!checkProduct(organization?.lob, product),
                                false
                              ),
                              getNestedTernaryData(
                                !!LOBDetails?.lob,
                                !!checkProduct(LOBDetails?.lob, product),
                                false
                              )
                            )}
                          />
                        }
                        label=''
                      />
                      <div className='product-item-button-div'></div>
                      <div className='product-item-button-div'>
                        <img alt='icon' className='img-fluid icon' src={ProductIcons[product]} />
                        <span className='product-label'>{product}</span>
                      </div>
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container spacing={2}>
                      {productList.map((item: any, index: number) => (
                        <Grid item xs={6} key={index}>
                          <Button
                            variant='outlined'
                            className={`product-btn-icon ${getNestedTernaryData(
                              disabled,
                              getNestedTernaryData(
                                !!organization?.lob &&
                                  !!organization?.lob[homeKey] &&
                                  !!checkProductHome(organization?.lob[homeKey], item),
                                'active',
                                ''
                              ),
                              getNestedTernaryData(
                                !!LOBDetails?.lob &&
                                  !!LOBDetails?.lob[homeKey] &&
                                  !!checkProductHome(LOBDetails?.lob[homeKey], item),
                                'active',
                                ''
                              )
                            )}`}
                            disableRipple
                            disabled={disabled || organizations?.addEditOrgLoader}
                            onClick={() =>
                              handleOnChangeProductHome(LOBDetails?.lob[homeKey], item)
                            }
                          >
                            <FormControlLabel
                              className='button-icon'
                              control={
                                <Checkbox
                                  icon={<RadioButtonUnchecked />}
                                  checkedIcon={<CheckCircle />}
                                  name={item}
                                  checked={getNestedTernaryData(
                                    disabled,
                                    getNestedTernaryData(
                                      !!organization?.lob &&
                                        !!checkProductHome(organization?.lob[homeKey], item),
                                      true,
                                      false
                                    ),
                                    getNestedTernaryData(
                                      !!LOBDetails?.lob &&
                                        !!LOBDetails?.lob[homeKey] &&
                                        !!checkProductHome(LOBDetails?.lob[homeKey], item),
                                      true,
                                      false
                                    )
                                  )}
                                />
                              }
                              label={item}
                            />
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid item xs={4} className='col-sm-4 margin-b-5' key={product}>
                  <Button
                    variant='outlined'
                    className={`product-item ${getNestedTernaryData(
                      disabled,
                      getNestedTernaryData(
                        !!organization?.lob && !!checkProduct(organization?.lob, product),
                        'active',
                        ''
                      ),
                      getNestedTernaryData(
                        !!LOBDetails?.lob && !!checkProduct(LOBDetails?.lob, product),
                        'active',
                        ''
                      )
                    )}`}
                    disableRipple
                    onClick={() => handleOnChangeLOBDetails(product)}
                    disabled={disabled || organizations?.addEditOrgLoader}
                  >
                    <FormControlLabel
                      className='button-icon'
                      control={
                        <Checkbox
                          icon={<RadioButtonUnchecked />}
                          name={product}
                          checkedIcon={<CheckCircle />}
                          checked={getNestedTernaryData(
                            disabled,
                            getNestedTernaryData(
                              !!organization?.lob,
                              !!checkProduct(organization?.lob, product),
                              false
                            ),
                            getNestedTernaryData(
                              !!LOBDetails?.lob,
                              !!checkProduct(LOBDetails?.lob, product),
                              false
                            )
                          )}
                        />
                      }
                      label=''
                    />
                    <div className='product-item-button-div'></div>
                    <div className='product-item-button-div'>
                      <img alt='icon' className='img-fluid icon' src={ProductIcons[product]} />
                      <span className='product-label'>
                        {getNestedTernaryData(
                          !!product && product?.toLowerCase() === EARTH_QUAKE_LOB,
                          INSURANCE_BUSINESS_TYPES.QUAKE,
                          getNestedTernaryData(
                            !!product && product?.toLowerCase() === LOB_LIST[6],
                            HOME_WARRANTY_LOB,
                            getNestedTernaryData(
                              !!product && product?.toLowerCase() === LOB_LIST[7],
                              CYBER_SECURITY_LOB,
                              product
                            )
                          )
                        )}
                      </span>
                    </div>
                  </Button>
                </Grid>
              )}
            </>
          ))}
        </Grid>
      </div>
      {!disabled && <FormHelperText className='text-red'>{lobErrors?.productError}</FormHelperText>}
    </>
  );

  const errorForm = (disabled: boolean = true) => (
    <form className='form row error-wrap'>
      <div className='mb-3 form-item text'>
        <InputLabel
          className={`form-item__label ${disabled ? ' w-50' : 'w-0 mr-3'}`}
          htmlFor='display_errors_on_ui'
        >
          Display Carrier Errors{disabled && ':'}
        </InputLabel>
        <span className={`p-0 d-flex justify-content-end ${disabled ? 'w-50' : 'w-0'}`}>
          <CustomSwitch
            checked={
              disabled ? !!organization?.display_errors_on_ui : !!errorDetails?.display_errors_on_ui
            }
            onChange={handleOnChangeErrorCheck}
            name='display_errors_on_ui'
            disabled={disabled || organizations?.addEditOrgLoader}
          />
        </span>
      </div>

      <div className='mb-4 form-item text align-items-start'>
        <InputLabel
          className='col-12 form-item__label mb-2'
          htmlFor='error_text'
          error={!!errorErrors?.errorText && !disabled ? true : false}
        >
          Error Text{!disabled ? <span className='small-star-error'>{ASTERISK}</span> : ':'}
        </InputLabel>
        {!disabled ? (
          <>
            <InputBase
              inputProps={{
                name: 'error_text',
                id: 'error_text',
                spellCheck: 'false',
                maxLength: 200,
              }}
              className='form-item__input'
              value={getNestedTernaryData(
                disabled,
                organization?.customized_error_page_details?.error_text,
                errorDetails?.customized_error_page_details?.error_text
              )}
              onChange={handleOnChangeErrorInput}
              disabled={disabled || organizations?.addEditOrgLoader}
            />
            <FormHelperText className='text-red'>{errorErrors?.errorText}</FormHelperText>
          </>
        ) : (
          <Typography className='normal-font error-text-input'>
            {getNestedTernaryData(
              !isEmpty(organization?.customized_error_page_details?.error_text),
              organization?.customized_error_page_details?.error_text,
              ''
            )}
          </Typography>
        )}
      </div>
      <div className='mb-2 form-item text align-items-start'>
        <InputLabel
          className='col-12 form-item__label mb-2'
          htmlFor='error_logo_url'
          error={!!errorErrors?.errorLogoUrl && !disabled ? true : false}
        >
          Error Logo URL{!disabled ? <span className='small-star-error'>{ASTERISK}</span> : ':'}
        </InputLabel>

        {!disabled ? (
          <>
            <InputBase
              inputProps={{
                name: 'error_logo_url',
                id: 'error_logo_url',
                spellCheck: 'false',
                maxLength: 100,
              }}
              className='form-item__input'
              value={getNestedTernaryData(
                disabled,
                organization?.customized_error_page_details?.error_logo_url,
                errorDetails?.customized_error_page_details?.error_logo_url
              )}
              onChange={handleOnChangeErrorInput}
              disabled={disabled || organizations?.addEditOrgLoader}
            />
            <FormHelperText className='text-red'>{errorErrors?.errorLogoUrl}</FormHelperText>
          </>
        ) : (
          <Typography className='normal-font error-text-input'>
            {getNestedTernaryData(
              !isEmpty(organization?.customized_error_page_details?.error_logo_url),
              organization?.customized_error_page_details?.error_logo_url,
              ''
            )}
          </Typography>
        )}
      </div>
    </form>
  );

  const drawSettingsContent = () => (
    <>
      <div className='row m-0 card-padding'>
        <div className='col-md-12 col-lg-4 p-2 pt-3 pb-3'>
          <div className='col-md-12 settings'>
            <div className='row mb-2'>
              <div className='col-md-12 d-flex'>
                <div className='settings-title'>Rate Settings</div>
                {!organizations?.getOrganizationLoader && (
                  <div className='edit-icon-wrapper'>
                    <div className='card-edit-svg'>
                      <span
                        title={EDIT}
                        onClick={() => setModalSelected('rateSettingsModal')}
                        className='icon-edit1 text-color'
                      ></span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!organizations?.getOrganizationLoader && rateSettingsForm()}
            {!!organizations?.getOrganizationLoader && <CardSkeleton />}
          </div>
        </div>
        <div className='col-md-12 col-lg-4 p-2 pt-3 pb-3'>
          <div className='col-md-12 settings'>
            <div className='row mb-3'>
              <div className='col-md-12 d-flex'>
                <div className='settings-title'>LOB</div>
                {!organizations?.getOrganizationLoader && (
                  <div className='edit-icon-wrapper'>
                    <div className='card-edit-svg'>
                      <span
                        title={EDIT}
                        onClick={() => setModalSelected('LOBModal')}
                        className='icon-edit1 text-color mr-0'
                      ></span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!organizations?.getOrganizationLoader && lobDetailsForm()}
            {!!organizations?.getOrganizationLoader && <CardSkeleton />}
          </div>
        </div>
        <div className='col-md-12 col-lg-4 p-2 pt-3 pb-3'>
          <div className='col-md-12 settings'>
            <div className='row mb-2'>
              <div className='col-md-12 d-flex'>
                <div className='settings-title'>Error</div>
                {!organizations?.getOrganizationLoader && (
                  <div className='edit-icon-wrapper'>
                    <div className='card-edit-svg'>
                      <span
                        title={EDIT}
                        onClick={() => setModalSelected('errorModal')}
                        className='icon-edit1 text-color'
                      ></span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!organizations?.getOrganizationLoader && errorForm()}
            {!!organizations?.getOrganizationLoader && <CardSkeleton />}
          </div>
        </div>
      </div>
    </>
  );

  const drawCrmContent = () => (
    <div className='crm-card-wrapper position-relative'>
      {!organizations?.getOrganizationLoader && (
        <span
          title={EDIT}
          onClick={() => setIsEditCrm(true)}
          className='icon-edit1 edit-svg text-color'
        ></span>
      )}
      <ViewCrmDetails />
    </div>
  );

  const drawAMSContent = () => (
    <div className='ams-card-padding position-relative'>
      {!organizations?.getOrganizationLoader && (
        <span
          title={EDIT}
          onClick={() => setIsEditAMSData(true)}
          className='icon-edit1 edit-svg text-color'
        ></span>
      )}
      <form className='form'>
        <div className='col-md-12 mb-4 form-item text'>
          <div className='col-lg-2 col-md-6 p-0 mob-wdt-50'>
            <img alt='ams button' className='ams-button' src={AMSButtonImg} />
          </div>
          <div className='col-lg-1 col-md-6 p-0 mob-wdt-50 sm-right-align d-md-flex justify-content-md-end'>
            <Switch
              color='primary'
              inputProps={{ 'aria-label': 'primary checkbox' }}
              value={!!editOrganizationData?.ams_enabled ? true : false}
              checked={!!editOrganizationData?.ams_enabled ? true : false}
              disabled={true}
            />
          </div>
        </div>
        <div className='m-0 ams-tab-form'>
          <div className='row'>
            <div className='col-lg-6 col-row-mb'>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor='loginId'>
                    Username:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.loginId
                        ? editOrganizationData?.ams_credentials?.loginId
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.loginId
                      ? editOrganizationData?.ams_credentials?.loginId
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label ' htmlFor='password'>
                    Password:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography className='normal-font ams-tab-data'>
                    {!!editOrganizationData?.ams_credentials?.password ? ENCRYPTED_DATA : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor='agencyNumber'>
                    Agency Number:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.agencyNumber
                        ? editOrganizationData?.ams_credentials?.agencyNumber
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.agencyNumber
                      ? editOrganizationData?.ams_credentials?.agencyNumber
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor='divisionList'>
                    Division:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.divisionList
                        ? editOrganizationData?.ams_credentials?.divisionList
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.divisionList
                      ? editOrganizationData?.ams_credentials?.divisionList
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor='executive'>
                    Executive:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.executive
                        ? editOrganizationData?.ams_credentials?.executive
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.executive
                      ? editOrganizationData?.ams_credentials?.executive
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor='groupList'>
                    Group:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.groupList
                        ? editOrganizationData?.ams_credentials?.groupList
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.groupList
                      ? editOrganizationData?.ams_credentials?.groupList
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor='representativeList'>
                    Representative List:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.representativeList
                        ? editOrganizationData?.ams_credentials?.representativeList
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.representativeList
                      ? editOrganizationData?.ams_credentials?.representativeList
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label ' htmlFor='branch'>
                    Branch:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.branch
                        ? editOrganizationData?.ams_credentials?.branch
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.branch
                      ? editOrganizationData?.ams_credentials?.branch
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor='departmentList'>
                    Department:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.departmentList
                        ? editOrganizationData?.ams_credentials?.departmentList
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.departmentList
                      ? editOrganizationData?.ams_credentials?.departmentList
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-row-mb'>
             
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor='v2Host'>
                    Policy Header End Point:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.v2Host
                        ? editOrganizationData?.ams_credentials?.v2Host
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.v2Host
                      ? editOrganizationData?.ams_credentials?.v2Host
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor='v3Host'>
                    Get All Details End Point:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.v3Host
                        ? editOrganizationData?.ams_credentials?.v3Host
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.v3Host
                      ? editOrganizationData?.ams_credentials?.v3Host
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor='executiveList'>
                    Executive List:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.executiveList
                        ? editOrganizationData?.ams_credentials?.executiveList
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.executiveList
                      ? editOrganizationData?.ams_credentials?.executiveList
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor='brokerList'>
                    Broker List:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.brokerList
                        ? editOrganizationData?.ams_credentials?.brokerList
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.brokerList
                      ? editOrganizationData?.ams_credentials?.brokerList
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor='employeeList'>
                    Employee List:
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={
                      !!editOrganizationData?.ams_credentials?.employeeList
                        ? editOrganizationData?.ams_credentials?.employeeList
                        : ''
                    }
                  >
                    {!!editOrganizationData?.ams_credentials?.employeeList
                      ? editOrganizationData?.ams_credentials?.employeeList
                      : HTML_EM}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor={ALLOW_AMS_FOR_SALESFORCE.LABEL}>
                    {`${ALLOW_AMS_FOR_SALESFORCE.LABEL}:`}
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={!!editOrganizationData?.ams_for_salesforce_enabled ? YES : NO}
                  >
                    {!!editOrganizationData?.ams_for_salesforce_enabled ? YES : NO}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor={ALLOW_AMS_FOR_GATOR.LABEL}>
                    {`${ALLOW_AMS_FOR_GATOR.LABEL}:`}
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={!!editOrganizationData?.ams_for_gator_enabled ? YES : NO}
                  >
                    {!!editOrganizationData?.ams_for_gator_enabled ? YES : NO}
                  </Typography>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-5'>
                  <InputLabel className='form-item__label' htmlFor={ALLOW_ADHOC_BIND.LABEL}>
                    {`${ALLOW_ADHOC_BIND.LABEL}:`}
                  </InputLabel>
                </div>
                <div className='col-lg-7'>
                  <Typography
                    className='normal-font ams-tab-data text-elipsis'
                    title={!!editOrganizationData?.adhoc_bind_enabled ? YES : NO}
                  >
                    {!!editOrganizationData?.adhoc_bind_enabled ? YES : NO}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  const drawCarrierAppoinments = () => (
    <>
      <CarrierAppointmentsTable />
    </>
  );

  const lenderSettingContent = () => {
    return (
      <div>
        <Box position='relative'>
          <span
            title={EDIT}
            onClick={() => setIsEditLenderSetting(true)}
            className='icon-edit1 edit-svg text-color'
          ></span>
          <LenderSetting closeModal={closeModal} isEditLenderSetting={isEditLenderSetting} />
        </Box>
      </div>
    );
  };

  const drawCarrierIframeSettings = () => (
    <div>
      <Box position='relative'>
        <HcrIframeClient />
      </Box>
    </div>
  );

  const modals = (modalKey: string) => {
    switch (modalKey) {
      case 'addEditOrganizationModal':
        return (
          <AddEditOrganizationModal
            orgTitle={'Organization Controls'}
            onClose={closeModal}
            organizationRowData={editOrganizationData}
            loader={organizationEdit || !!organizations?.getOrganizationLoader}
          />
        );
      case 'rateSettingsModal':
        return (
          <Modal
            onClose={() => closeModal(initRateSettingsDetails)}
            onSave={() => updateData(rateSettingsDetails, validateRateRateSettings)}
            title='Rate Setting'
            maxWidth='sm'
            isOpen={true}
            loader={organizationEdit}
            navButtons
          >
            {rateSettingsForm(false)}
          </Modal>
        );

      case 'LOBModal':
        return (
          <Modal
            onClose={() => closeModal(initLOBDetails)}
            onSave={() => updateData(LOBDetails, validateLobDetails)}
            title='Line Of Business'
            maxWidth={XS}
            isOpen={true}
            loader={organizationEdit}
            navButtons
          >
            {lobDetailsForm(false)}
          </Modal>
        );
      case 'errorModal':
        return (
          <Modal
            onClose={() => closeModal(initErrorDetails)}
            onSave={() => updateData(errorDetails, validateError)}
            title='Error'
            maxWidth='sm'
            isOpen={true}
            loader={organizationEdit}
            navButtons
          >
            {errorForm(false)}
          </Modal>
        );

      default:
        return null;
    }
  };

  let tabContent = [
    {
      title: 'Settings',
      content: drawSettingsContent(),
    },
    {
      title: 'AMS',
      content: drawAMSContent(),
    },
    {
      title: 'Carrier Appointments',
      content: drawCarrierAppoinments(),
    },
    {
      title: SFDC_KEY?.SFDC,
      content: drawCrmContent(),
    },
    {
      title: LENDER_SETTING.LABEL,
      content: lenderSettingContent(),
    },
    {
      title: EOI_SETTINGS.TAB_LABEL,
      content: <EoiSettingsTable />,
    },
  ];

  if (organization?.hcr_iframe_client_enabled) {
    tabContent.push({
      title: 'IFrame Settings',
      content: drawCarrierIframeSettings(),
    });
  }

  const onBackclick = () => {
    history.push(ADMIN_ROUTE);
  };

  return (
    <div className='container organization'>
      <div className='row mb-4 mt-4'>
        <div className='col-sm-12 p-0'>
          <button onClick={onBackclick} className='link-label back-btn'>
            <KeyboardBackspace className='mr-1 left-arrow-icon' />
            Back
          </button>
        </div>
      </div>
      <Card className='organization-info mb-4'>
        <CardContent className='position-relative'>
          <div className='row'>
            {!organizations?.getOrganizationLoader && (
              <>
                <div className='col-lg-3'>
                  <div className='center-div-wrap'>
                    <div className='img-wrap'>
                      <img
                        className='organization-img'
                        src={
                          !!organization?.logo_url
                            ? organization?.logo_url
                            : getNestedTernaryData(
                                !!organization?.branding_on_dale_details?.logo_url,
                                organization?.branding_on_dale_details?.logo_url,
                                ''
                              )
                        }
                        alt={organization?.name || ''}
                      />
                    </div>
                    <div className='d-flex justify-content-center mt-3 padding-bottom'>
                      <div
                        className={`status-button ${
                          !!organization?.status && organization?.status.toLowerCase() === IN_ACTIVE
                            ? 'text-red'
                            : ''
                        }`}
                      >
                        {organization?.status}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-5 pl-4 organization-info__org devider-border'>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-6 col-sm-12'>POD Org Name:</span>
                    <Typography
                      className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.name ? organization?.name : ''}
                    >
                      {!!organization?.name ? organization?.name : HTML_EM}
                    </Typography>
                  </Typography>

                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-6 col-sm-12'>POD Org Code:</span>
                    <Typography
                      className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.code ? organization?.code : ''}
                    >
                      {!!organization?.code ? organization?.code : HTML_EM}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-6 col-sm-12'>POD Org ID:</span>
                    <Typography
                      className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.partner_id ? organization?.partner_id : ''}
                    >
                      {!!organization?.partner_id ? organization?.partner_id : HTML_EM}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters' id='pod-auth-token-wrapper'>
                    <span className='col-lg-6 col-sm-12'>POD Auth Token:</span>
                    <div className='col-lg-6 col-sm-12 d-flex justify-content-between'>
                      <Typography
                        className='normal-font info-item__text text-elipsis'
                        title={
                          !!organization?.auth_token && isShowPODAuthToken
                            ? organization?.auth_token
                            : ''
                        }
                      >
                        {!!organization?.auth_token
                          ? getNestedTernaryData(
                              isShowPODAuthToken,
                              organization.auth_token,
                              ENCRYPTED_DATA
                            )
                          : HTML_EM}
                      </Typography>
                      {!!organization?.auth_token && (
                        <span
                          className='visibility-icon'
                          onClick={() => setIsShowPODAuthToken(!isShowPODAuthToken)}
                        >
                          {isShowPODAuthToken ? <VisibilityOff /> : <Visibility />}
                        </span>
                      )}
                    </div>
                  </Typography>
                  {!!organization?.authentication &&
                    organization?.authentication === AUTHENTICATION_OPTIONS[1] && (
                      <>
                        <Typography className='info-item row no-gutters'>
                          <span className='col-lg-6 col-sm-12'>Username:</span>
                          <Typography
                            className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'
                            title={!!organization?.username ? organization?.username : ''}
                          >
                            {!!organization?.username ? organization?.username : HTML_EM}
                          </Typography>
                        </Typography>
                        <Typography className='info-item row no-gutters'>
                          <span className='col-lg-6 col-sm-12'>Password:</span>
                          <Typography className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'>
                            {!!organization?.password ? ENCRYPTED_DATA : HTML_EM}
                          </Typography>
                        </Typography>
                      </>
                    )}
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-6 col-sm-12'>Public Access to View Quote:</span>
                    <Typography
                      className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.public_access_view_quote ? YES : NO}
                    >
                      {!!organization?.public_access_view_quote ? YES : NO}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-6 col-sm-12'>{FEEDBACK_BUTTON}:</span>
                    <Typography
                      className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.feedback_enabled ? YES : NO}
                    >
                      {!!organization?.feedback_enabled ? YES : NO}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-6 col-sm-12'>{CONSUMER_POD_ID.LABEL}:</span>
                    <Typography
                      className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'
                      title={
                        !!organization?.consumer_partner_id ? organization?.consumer_partner_id : ''
                      }
                    >
                      {!!organization?.consumer_partner_id
                        ? organization?.consumer_partner_id
                        : HTML_EM}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-6 col-sm-12'>{CONSUMER_TOKEN.LABEL}:</span>
                    <div className='col-lg-6 col-sm-12 d-flex justify-content-between'>
                      <Typography
                        className='normal-font info-item__text text-elipsis'
                        title={
                          !!organization?.conVisibilityIconsumer_token && isShowConsumerToken
                            ? organization?.consumer_token
                            : ''
                        }
                      >
                        {!!organization?.consumer_token
                          ? getNestedTernaryData(
                              isShowConsumerToken,
                              organization.consumer_token,
                              ENCRYPTED_DATA
                            )
                          : HTML_EM}
                      </Typography>
                      {!!organization?.consumer_token && (
                        <span
                          className='visibility-icon'
                          onClick={() => setIsShowConsumerToken(!isShowConsumerToken)}
                        >
                          {isShowConsumerToken ? <VisibilityOff /> : <Visibility />}
                        </span>
                      )}
                    </div>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-6 col-sm-12'>{INCLUDE_IN_REPORT.LABEL}:</span>
                    <Typography
                      className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.report_enabled ? YES : NO}
                    >
                      {!!organization?.report_enabled ? YES : NO}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-6 col-sm-12'>{HEAP_ANALYTICS.LABEL}:</span>
                    <Typography
                      className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.heap_analytics_enabled ? YES : NO}
                    >
                      {!!organization?.heap_analytics_enabled ? YES : NO}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-6 col-sm-12'>{PROPERTY_CONSUMER_ID.LABEL}:</span>
                    <Typography
                      className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.property_consumer_id ? organization?.property_consumer_id : ''}
                    >
                      {!!organization?.property_consumer_id ? organization?.property_consumer_id : HTML_EM}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-6 col-sm-12'>{ALLOW_DOWNLOAD_QUOTE.LABEL}:</span>
                    <Typography className='info-item row no-gutters allow-download-quote-label-wrap'>
                      <Typography className='col-lg-6 col-sm-12 allow-download-quote-label'>
                        <b>{ALLOW_DOWNLOAD_QUOTE.HOME_QUOTE.LABEL}:</b>
                        <span
                        className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'
                        title={!!organization?.enable_home_quote_doc_download ? YES : NO}
                      >
                        {!!organization?.enable_home_quote_doc_download ? YES : NO}
                      </span>
                      </Typography>
                    </Typography>
                    <Typography className='info-item row no-gutters allow-download-quote-label-wrap'>
                      <Typography className='col-lg-6 col-sm-12 allow-download-quote-label'>
                        <b>{ALLOW_DOWNLOAD_QUOTE.AUTO_QUOTE.LABEL}:</b>
                        <span
                        className='normal-font col-lg-6 col-sm-12 info-item__text text-elipsis'
                        title={!!organization?.enable_auto_quote_doc_download ? YES : NO}
                      >
                        {!!organization?.enable_auto_quote_doc_download ? YES : NO}
                      </span>
                      </Typography>
                    </Typography>
                  </Typography>
                </div>
                <div className='col-lg-4 pl-4 organization-info__org'>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-7 col-sm-12'>{`${DOWNLOAD_QUOTE_PREFIX}:`}</span>
                    <Typography
                      className='normal-font col-lg-5 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.abbreviation ? organization?.abbreviation : ''}
                    >
                      {!!organization?.abbreviation ? organization?.abbreviation : HTML_EM}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-7 col-sm-12'>Theme:</span>
                    <Typography
                      className='normal-font col-lg-5 col-sm-12 info-item__text text-elipsis'
                      title={
                        !!organization?.branding_on_dale_details?.template_name
                          ? getThemeName(organization?.branding_on_dale_details?.template_name)
                          : ''
                      }
                    >
                      <>
                        {!!organization?.branding_on_dale_details?.template_name
                          ? getThemeName(organization?.branding_on_dale_details?.template_name)
                          : HTML_EM}
                      </>
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-7 col-sm-12'>Authentication:</span>
                    <Typography
                      className='normal-font col-lg-5 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.authentication ? organization?.authentication : ''}
                    >
                      {!!organization?.authentication ? organization?.authentication : HTML_EM}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-7 col-sm-12'>Phone:</span>
                    <Typography
                      className='normal-font col-lg-5 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.contact?.phone ? organization?.contact?.phone : ''}
                    >
                      {!!organization?.contact?.phone ? organization?.contact?.phone : HTML_EM}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-7 col-sm-12'>Email:</span>
                    <Typography
                      className='normal-font col-lg-5 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.contact?.email ? organization?.contact?.email : ''}
                    >
                      {!!organization?.contact?.email ? organization?.contact?.email : HTML_EM}
                    </Typography>
                  </Typography>
                  {!!organization?.authentication &&
                    organization?.authentication === AUTHENTICATION_OPTIONS[1] && (
                      <>
                        <Typography className='info-item row no-gutters'>
                          <span className='col-lg-7 col-sm-12'>Webhook:</span>
                          <Typography
                            className='normal-font col-lg-5 col-sm-12 info-item__text text-elipsis'
                            title={!!organization?.webhook ? organization?.webhook : ''}
                          >
                            {!!organization?.webhook ? organization?.webhook : HTML_EM}
                          </Typography>
                        </Typography>
                        <Typography className='info-item row no-gutters'>
                          <span className='col-lg-7 col-sm-12'>Signature:</span>
                          <Typography
                            className='normal-font col-lg-5 col-sm-12 info-item__text text-elipsis'
                            title={!!organization?.signature ? organization?.signature : ''}
                          >
                            {!!organization?.signature ? organization?.signature : HTML_EM}
                          </Typography>
                        </Typography>
                      </>
                    )}
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-7 col-sm-12'>Visible on Gator UI:</span>
                    <Typography
                      className='normal-font padding-left-3 col-5 info-item__text text-elipsis'
                      title={!!organization?.visible_on_gator_ui ? YES : NO}
                    >
                      {!!organization?.visible_on_gator_ui ? YES : NO}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-7 col-sm-12'>{JV_HOME_URL.LABEL}:</span>
                    <Typography
                      className='normal-font col-lg-5 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.base_url ? organization?.base_url : ''}
                    >
                      {!!organization?.base_url ? organization?.base_url : HTML_EM}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-7 col-sm-12'>{INCLUDE_GATOR_QUOTING.LABEL}:</span>
                    <Typography
                      className='normal-font padding-left-3 col-5 info-item__text text-elipsis'
                      title={!!organization?.gator_quoting_enabled ? YES : NO}
                    >
                      {!!organization?.gator_quoting_enabled ? YES : NO}
                    </Typography>
                  </Typography>
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-7 col-sm-12'>{HCR_ORGANIZATION.LABEL}:</span>
                    <Typography
                      className='normal-font col-lg-5 col-sm-12 info-item__text text-elipsis'
                      title={!!organization?.hcr_org_name ? organization?.hcr_org_name : ''}
                    >
                      {!!organization?.hcr_org_name ? organization?.hcr_org_name : HTML_EM}
                    </Typography>
                  </Typography>
                  {!!organization?.heap_analytics_enabled && (
                    <Typography className='info-item row no-gutters'>
                      <span className='col-lg-7 col-sm-12'>{HEAP_PROJECT_ID.LABEL}:</span>
                      <Typography
                        className='normal-font col-lg-5 col-sm-12 info-item__text text-elipsis'
                        title={!!organization?.heap_project_id ? organization?.heap_project_id : ''}
                      >
                        {!!organization?.heap_project_id ? organization?.heap_project_id : HTML_EM}
                      </Typography>
                    </Typography>
                  )}
                  <Typography className='info-item row no-gutters'>
                    <span className='col-lg-7 col-sm-12'>{BUILDER_ORGANIZATION.LABEL}:</span>
                    <Typography
                      className='normal-font padding-left-3 col-5 info-item__text text-elipsis'
                      title={!!organization?.is_builder_organization ? YES : NO}
                    >
                      {!!organization?.is_builder_organization ? YES : NO}
                    </Typography>
                  </Typography>
                </div>
              </>
            )}
          </div>
          {!!organizations?.getOrganizationLoader && <CardSkeleton />}
          {!organizations?.getOrganizationLoader && (
            <div className='edit-svg'>
              <span
                title={EDIT}
                onClick={() => setModalSelected('addEditOrganizationModal')}
                className='icon-edit1 text-color'
              ></span>
            </div>
          )}
        </CardContent>
      </Card>

      <div className='row organization-model-tab'>
        <Tabs>
          {tabContent.map((tab, index) => (
            <div key={index} title={tab.title}>
              {tab.content}
            </div>
          ))}
        </Tabs>
      </div>
      {isEditAMSData && <AmsComponent closeModal={closeModal} />}
      {!!isEditCrm && <CRMComponent closeModal={closeModal} />}
      {modals(modalSelected)}
    </div>
  );
};

const mapStateToProps = ({ organizations }: AppComponents.OrganizationDetailsStore) => {
  const { editOrganizationData } = organizations;
  return { organizations, editOrganizationData };
};

const mapDispatchToProps = (dispatch: any): AppComponents.OrganizationEditorDispatch => {
  return bindActionCreators(
    {
      setOrgAccountsListData,
      updateOrganizationData,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationEditor);
